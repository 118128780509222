/*
 * @fileOverview DropdownView module definition
 */

import View from 'views/View';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'CONTENT_SWAP': '.js-dropdown-contentSwap',
  'TRIGGER': '.js-dropdown-trigger',
  'TRIGGER_ICON': '.js-dropdown-trigger-icon',
  'TRIGGER_TEXT': '.js-dropdown-activeText',
  'LI_BTN': '.js-dropdown-list-item-btn',
  'LIST': '.js-dropdown-list'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'DROPDOWN': 'dropdown',
  'LIST_ACTIVE': 'dropdown-list_active',
  'ROTATE_ICON_270': 'mix-icon_rotate270',
  'ROTATE_ICON_180': 'mix-icon_rotate180',
  'ROTATE_ICON_90': 'mix-icon_rotate90',
  'CONDITIONS': 'navConditions'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'FOCUSOUT': 'focusout'
};

/**
 * Sets up all event handling for any class that extends DropdownView
 *
 * @class DropdownView
 */
export default class DropdownView extends View {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {DropdownView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$window = $(window);
    this.$contentSwap = this.$element.find(SELECTORS.CONTENT_SWAP);
    this.$list = this.$element.find(SELECTORS.LIST);
    this.$listitems = this.$element.find(SELECTORS.LI_BTN);
    this.$trigger = this.$element.find(SELECTORS.TRIGGER);
    this.$triggerIcon = this.$element.find(SELECTORS.TRIGGER_ICON);
    this.$triggerText = this.$element.find(SELECTORS.TRIGGER_TEXT);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {DropdownView}
   * @chainable
   * @public
   */
  enable() {
    this.$listitems.on(EVENTS.CLICK, e => this.updateAction(e));
    this.$trigger.on(EVENTS.CLICK, e => this.toggleDropdown(e));
    this.$window.on(EVENTS.CLICK, e => this.handleOffClick(e));
    this.$listitems.last().on(EVENTS.FOCUSOUT, e => this.handleLastItemFocusOut(e));

    return this;
  }

  afterInit() {
    this.isOpen = false;
  }

  /**
   * Handles a click on the window and determines if it is not within the dropdown.
   * If it is NOT, then it will toggle the dropdown closed.
   *
   * @method handleOffClick
   * @param {Object} event [Javascript event object]
   */
  handleOffClick(event) {
    const notInElement = !this.$element[0].contains(event.target);
    const notConditions = $(event.target).closest(`.${CLASSES.CONDITIONS}`).length === 0;

    if (this.isOpen && notInElement && notConditions) {
      this.toggleDropdown(null, true);
    }
  }

  /**
   * Handles a focus out of the last item in the options list.
   * Captures the event and sends focus back to the first item in the list.
   *
   * @method handleLastItemFocusOut
   * @param {Object} event [Javascript event object]
   */
  handleLastItemFocusOut(event) {
    const notInElement = $(event.relatedTarget).closest(`.${CLASSES.DROPDOWN}`).length === 0;

    if (notInElement) {
      this.$listitems.eq(0).focus();
    }
  }

  /**
   * Toggles the current state of the dropdown list
   *
   * @method toggleDropdown
   * @param {Object} event [Javascript event object]
   * @param {Bool} isBodyClick [whether the originating call is from a body click]
   */
  toggleDropdown(event, isBodyClick = false) {
    let rotateClass = this.$triggerIcon.hasClass(CLASSES.ROTATE_ICON_90) ? CLASSES.ROTATE_ICON_270 : CLASSES.ROTATE_ICON_180;

    this.$list.toggleClass(CLASSES.LIST_ACTIVE);
    this.$triggerIcon.toggleClass(rotateClass);

    if (this.isOpen && !isBodyClick) {
      this.$trigger.focus();
    } else if (!this.isOpen && !isBodyClick) {
      this.$listitems.eq(0).focus();
    }

    this.isOpen = !this.isOpen;
  }

  /**
   * Updates the dropdown trigger text to the selected item
   *
   * @param {string} text [The text value that the trigger text should be updated to]
   * @method updateTriggerText
   */
  updateTriggerText(text) {
    $(this.$triggerText).text(text);
  }

  /**
   * ***IMPORTANT***
   * This method is void and SHOULD NOT be updated in this class.
   * Method should be overridden by classes that extend this class.
   *
   * @method updateAction
   */
  updateAction () {}
}
