/*
 * @fileOverview GalleryView module definition
 */

import View from 'views/View';
import ModalView from 'views/ModalView';
import CarouselView from 'views/CarouselView'
import { TweenMax, Power1 } from 'views/GsapLoader';
import Utils from 'utils/Utils';
import CustomVimeoPlayer from 'services/CustomVimeoPlayer';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'MODAL': '.js-gallery-modal',
  'ITEMS': '.js-gallery-item',
  'CAROUSEL': '.js-carousel',
  'VIDEO': '.js-lightbox-video'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'ACTIVE': 'fadeIn_active'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'FOCUSOUT': 'focusout',
  'KEYDOWN': 'keydown',
  'FOCUS': 'focus'
};

/**
 * Handle the Gallery Lightbox
 *
 * @class GalleryView
 */
export default class GalleryView extends CarouselView {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {GalleryView}
   * @public
   * @chainable
   */
  createChildren() {
    super.createChildren();

    this.$modal = this.$element.find(SELECTORS.MODAL);
    this.$modalView = new ModalView(this.$modal, {
      'afterHide': this.afterModalHide.bind(this),
      'focusContent': this.focusContent.bind(this),
      'focusAfterContent': this.focusNextButton.bind(this),
      'focusBeforeContent': this.focusPreviousButton.bind(this)
    });
    this.$galleryItems = this.$element.find(SELECTORS.ITEMS);
    this.$videos = this.$element.find(SELECTORS.VIDEO);
    this.players = {};
    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {GalleryView}
   * @chainable
   * @public
   */
  enable() {
    super.enable();

    this.$galleryItems.on(`${EVENTS.CLICK} ${EVENTS.KEYDOWN}`, e => this.openLightbox(e));
    this.$modalView.$close.on(EVENTS.FOCUSOUT, e => this.handleTab(e, this.focusPreviousButton.bind(this), this.focusNextButton.bind(this)));
    this.$videos.on(EVENTS.FOCUSOUT, e => this.handleTab(e, this.focusNextButton.bind(this), this.focusPreviousButton.bind(this)));
    this.$leftButton.on(EVENTS.FOCUSOUT, e => this.handleTab(e, this.focusContent.bind(this), this.focusClose.bind(this)));
    this.$rightButton.on(EVENTS.FOCUSOUT, e => this.handleTab(e, this.focusClose.bind(this), this.focusContent.bind(this)));
    return this;
  }

  /**
   * [Open the lightbox to the photo or video you clicked on in the gallery]
   * @param  {event} e [the click event]
   * @return {void}   [void]
   */
  openLightbox(e) {
    if (e.type === 'keydown' && e.keyCode !== 13) return;
    if (this.lightboxOpen) return;
    this.lightboxOpen = true;
    e.preventDefault();
    this.$lastFocus = $(':focus');
    this.currentItem = $(e.currentTarget).data('index') || 0;
    this.doScroll();
    this.$modalView.show();
    this.$modalView.$close.focus();
    Utils.swipeDetect(document, swipeDir => this.handleTouchMove(swipeDir));
  }

  /**
   * [doScroll scrolls to the clicked on item or the next or previous item]
   * @return {void}
   */
  doScroll() {
    const $nextItem = this.$items.eq(this.currentItem);
    this.$currentVideo = $nextItem.find(SELECTORS.VIDEO);

    this.$items.removeClass(CLASSES.ACTIVE);
    this.stopCurrentVideo();

    if (this.$currentVideo.length > 0) {
      this.currentPlayer = this.getVideoPlayer(this.$currentVideo);
    }

    $nextItem.addClass(CLASSES.ACTIVE);
    this.toggleArrows();
  }

  /**
   * [getVideoPlayer gets and stores a refernce to video players]
   * @param  {jquery element} $videoContainer [the video container that holds the iframe]
   */
  getVideoPlayer($videoContainer) {
    const playerId = $videoContainer.attr('id');
    const videoId = $videoContainer.data('video-id');

    let playerType = $videoContainer.data('video-service');
    let player = this.players[playerId];

    if (!player) {
      try {
        if (playerType === 'vimeo') {
          const ieVersion = Utils.getInternetExplorerVersion();
          if (ieVersion === -1 || ieVersion > 10) {
            var options = {
              id: videoId
            };

            player = new Vimeo.Player(playerId, options);
          }
          else {
            player = new CustomVimeoPlayer($videoContainer);
            playerType = 'custom';
          }
        }
        else if (playerType === 'youtube') {
          player = new YT.Player(playerId, {
            videoId: videoId
          });
        }
        player.playerServiceType = playerType;
      }
      catch(err) {
        player = null;
        playerType = 'notSupported';
      }

      this.players[playerId] = player;
    }

    return player;
  }

  /**
   * [stopCurrentVideo stops the currently playing video, if one in fact is playing]
   */
  stopCurrentVideo() {
    try {
      if (this.currentPlayer) {
        if (this.currentPlayer.playerServiceType === 'vimeo' && Utils.isFunction(this.currentPlayer.pause)) {
          this.currentPlayer.pause();
        }
        else if (this.currentPlayer.playerServiceType === 'youtube' && Utils.isFunction(this.currentPlayer.stopVideo)) {
          this.currentPlayer.stopVideo();
        }
        else if (this.currentPlayer.playerServiceType === 'custom') {
          this.currentPlayer.restart();
        }
      }
    }
    catch(err) {}
  }

  /**
   * hook for doing stuff after hiding the modal, such as stoping videos
   * @return {void} [description]
   */
  afterModalHide() {
    //stop videos
    this.stopCurrentVideo();
    Utils.removeSwipeDetect(document, swipeDir => this.handleTouchMove(swipeDir));
    if (this.$lastFocus) {
      this.$lastFocus.focus();
    }
    this.lightboxOpen = false;
  }

  /**
   * [handleTouchMove scrolls right or left as if the arrows were clicked]
   * @param  {string} swipeDir [right or left]
   */
  handleTouchMove(swipeDir) {
    if (swipeDir === 'left') {
      this.scrollRight();
    } else if (swipeDir === 'right')  {
      this.scrollLeft();
    }
  }

  /**
   * [setListWidth override the base carousel to do nothing as this is not a width based scroller]
   */
  setListWidth() {
    //do nothing
  }

  /**
   * [focusNextButton focus on the next button, or focus modal close]
   * @return {boolean} [always true for this class]
   */
  focusNextButton() {
    if (!super.focusNextButton()) {
      if (this.$modalView.direction === 'forward') {
          this.$modalView.$close.focus();
      }
      else {
        return this.focusContent();
      }
    }

    return true;
  }

  /**
   * [focusClose focus the close button]
   * @return {void}
   */
  focusClose() {
    this.$modalView.$close.focus();
  }

  /**
   * [focus the content in the modal if it is focusable otherwise take into consideration the direction and focus the next thing.]
   * @return {void}
   */
  focusContent() {
      const $iframe = this.$modalView.$element.find('iframe:visible');
      if ($iframe && $iframe.length > 0) {
        $iframe.focus();
      }
      else {
        if (this.$modalView.direction === 'forward') {
          this.focusNextButton();
        }
        else {
          this.focusPreviousButton();
        }
      }
  }

  /**
   * [focusPreviousButton focus the previous button, or focus modal close]
   * @return {boolean} [always true for this class]
   */
  focusPreviousButton() {
    if (!super.focusPreviousButton()) {
      if (this.$modalView.direction === 'forward') {
        this.focusContent();
      }
      else {
        this.$modalView.$close.focus();
      }
    }
  }

  /**
   * handle the tab event
   * @param  {event} e                the keydown event
   * @param  {function} forwardCallback  function to call for tab
   * @param  {function} backwardCallback function to call for tab back
   * @return {bool}                  boolean for success
   */
  handleTab(e, forwardCallback, backwardCallback) {
    if (this.$modalView.direction === 'forward') {
      return forwardCallback();
    }
    else {
      return backwardCallback();
    }
  }
}
