/*
 * @fileOverview HiddenButFocusableView module definition
 */

import View from 'views/View';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'TABTRIGGER': '.js-tab-trigger, a',
  'HOVERTRIGGER': '.js-hover-trigger',
  'VISUALLYHIDDEN': '.js-visuallyHidden'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'HIDDEN': 'isVisuallyHidden'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'BLUR': 'blur',
  'FOCUS': 'focus',
  'HOVER': 'mouseenter',
  'MOUSEOUT': 'mouseleave'
};

/**
 * Sets up all event handling for any class that extends HiddenButFocusableView
 *
 * @class HiddenButFocusableView
 */
export default class HiddenButFocusableView extends View {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {HiddenButFocusableView}
   * @public
   * @chainable
   */
  createChildren() {
    //tabbable and hover could be the module itself, so check there too
    this.$tabTrigger = this.$element.find(SELECTORS.TABTRIGGER).addBack().filter(SELECTORS.TABTRIGGER);
    this.$hoverTrigger = this.$element.find(SELECTORS.HOVERTRIGGER).addBack().filter(SELECTORS.HOVERTRIGGER);
    this.$visuallyHidden = this.$element.find(SELECTORS.VISUALLYHIDDEN).addBack().filter(SELECTORS.VISUALLYHIDDEN);
    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {HiddenButFocusableView}
   * @chainable
   * @public
   */
  enable() {
    this.$tabTrigger.on(EVENTS.BLUR, () => this.handleBlur());
    this.$tabTrigger.on(EVENTS.FOCUS, () => this.handleFocus());
    this.$hoverTrigger.on(EVENTS.HOVER, () => this.handleHover());
    this.$hoverTrigger.on(EVENTS.MOUSEOUT, () => this.handleMouseLeave());

    return this;
  }

  /**
   * handle focus on tab-trigger elements
   *
   * @method handleFocus
   * @returns {void}
   * @public
   */
  handleFocus(event) {
    this.showModule();
  }

  /**
   * handle blur on tab-trigger elements
   *
   * @method handleBlur
   * @returns {void}
   * @public
   */
  handleBlur(event) {
    this.hideModule();
  }

  /**
   * handle hover on hover-trigger elements
   *
   * @method handleHover
   * @returns {void}
   * @public
   */
  handleHover(event) {
    this.showModule();
  }

  /**
   * handle mouseout on hover-trigger elements
   *
   * @method handleMouseLeave
   * @returns {void}
   * @public
   */
  handleMouseLeave(event) {
    this.hideModule();
  }

  /**
   * Show the visually hidden element
   *
   * @method showModule
   * @returns {void}
   * @public
   */
  showModule() {
    this.$visuallyHidden.removeClass(CLASSES.HIDDEN);
  }

  /**
   * Hide the visually hidden element
   *
   * @method hideModule
   * @returns {void}
   * @public
   */
  hideModule() {
    this.$visuallyHidden.addClass(CLASSES.HIDDEN);
  }
}
