/*
 * @fileOverview NavView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'NAV': '.js-nav',
  'NAV_BAR': '.js-navBar',
  'NAV_BAR_TRIGGER': '.js-navBar-trigger',
  'PRIMARY_NAV': '.js-primaryNav',
  'PRIMARY_NAV_BTN': '.js-primaryNav-btn',
  'PRIMARY_NAV_ITEM': '.js-primaryNav-item',
  'SECONDARY_NAV': '.js-secondaryNav',
  'SECONDARY_NAV_DESKTOP': '.js-secondary-nav-desktop',
  'SECONDARY_NAV_BACK_BTN': '.js-secondaryNav-back',
  'SECONDARY_NAV_GROUP': '.js-secondaryNav-group',
  'SECONDARY_NAV_BTN': '.js-secondaryNav-btn',
  'SECONDARY_NAV_BTN_ICON_OPEN': '.js-icon-open',
  'SECONDARY_NAV_BTN_ICON_CLOSED': '.js-icon-closed',
  'SECONDARY_NAV_LINK': '.js-secondaryNav-link',
  'UTILITY_LINKS': '.js-utilityLinks',
  'SEARCH_TRIGGER': '.js-search-trigger',
  'STICKY_NAVBAR': '.js-stickyNavbar',
  'PRIMARY_NAVBAR': '.primaryNavBar',
  'UTILITYBAR': '.utilityNav'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'BODY_FIXED': 'noScroll',
  'NAV_OPEN': 'nav_open',
  'VISUALLY_HIDDEN': 'isVisuallyHidden',
  'ICON_HIDE': 'mix-icon_hide',
  'NAV_BAR_ACTIVE': 'navBar_active',
  'NAV_BAR_BUTTON': 'btn_navBar',
  'PRIMARY_NAV_ACTIVE': 'primaryNav_active',
  'PRIMARY_NAV_OPEN': 'primaryNav_open',
  'PRIMARY_NAV_ITEM_ACTIVE': 'primaryNav-item_active',
  'SECONDARY_NAV_OPEN': 'secondaryNav_open',
  'SECONDARY_NAV_GROUP_OPEN': 'secondaryNav-group_open',
  'UTILITY_LINKS_ACTIVE': 'utilityLinks_active',
  'CONDITIONS': 'navConditions',
  'SEARCH': 'modal_search',
  'BODY_STICKY_NAVBAR': 'body_withStickyNavbar'
};

const DATA = {
  'ACTIVE_ITEM': 'activeitem',
  'NAV_GROUP': 'navgroup',
  'OPEN': 'open'
}

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'FOCUSOUT': 'focusout',
  'KEYDOWN': 'keydown',
  'SCROLL': 'scroll'
};

/**
 * Container object for storing times
 *
 * @constant TIMES
 * @type {Object}
 */
const TIMES = {
  'THROTTLE': 25
};

// Defualt distance user has to scroll to
// show the sticky nav
const DEFAULT_SCROLL_DISTANCE = 0;
/**
 * Toggles subNav height and aria roles in an NavView view component
 *
 * @class NavView
 */
export default class NavView extends View {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);

    this.$element.data(DATA.ACTIVE_ITEM, '');
    this.$element.data(DATA.OPEN, false);
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {NavView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$window = $(window);
    this.$body = $('html,body');
    this.$navBar = this.$element.find(SELECTORS.NAV_BAR);
    this.$navBarTrigger = this.$element.find(SELECTORS.NAV_BAR_TRIGGER);
    this.$primaryNav = this.$element.find(SELECTORS.PRIMARY_NAV);
    this.$primaryNavBtns = this.$element.find(SELECTORS.PRIMARY_NAV_BTN);
    this.$primaryNavItems = this.$element.find(SELECTORS.PRIMARY_NAV_ITEM);
    this.$secondaryNav = $(SELECTORS.SECONDARY_NAV);
    this.$secondaryNavTrigger = this.$secondaryNav.find(SELECTORS.SECONDARY_NAV_BTN);
    this.$secondaryNavBackBtn = this.$secondaryNav.find(SELECTORS.SECONDARY_NAV_BACK_BTN);
    this.$secondaryNavLinks = this.$secondaryNav.find(SELECTORS.SECONDARY_NAV_LINK);
    this.$utilityLinks = this.$element.find(SELECTORS.UTILITY_LINKS);
    this.$searchTrigger = $(SELECTORS.SEARCH_TRIGGER);
    this.$stickyNavbar = $(SELECTORS.STICKY_NAVBAR);
    this.$primaryNavBar = $(SELECTORS.PRIMARY_NAVBAR);
    this.$utilityBar = $(SELECTORS.UTILITYBAR);

    return this;
  }

  /**
   * Remove any child objects or references to DOM elements.
   *
   * @method removeChildren
   * @returns {NavView}
   * @public
   * @chainable
   */
  removeChildren() {
    this.$navBar = null;
    this.$navBarTrigger = null;
    this.$primaryNav = null;
    this.$primaryNavBtns = null;
    this.$primaryNavItems = null;
    this.$secondaryNav = null;
    this.$secondaryNavBackBtn = null;
    this.$secondaryNavLinks = null;
    this.$searchTrigger = null;

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {NavView}
   * @chainable
   * @public
   */
  enable() {
    this.$window.on(EVENTS.CLICK, e => this.closeNav(e));
    this.$element.on(EVENTS.CLICK, e => this.handleClick(e));
    this.$navBarTrigger.on(EVENTS.CLICK, () => this.toggleNav());
    this.$primaryNavBtns.on(EVENTS.CLICK, e => this.setActiveNavItem(e));
    this.$secondaryNav.on(EVENTS.CLICK, e => this.handleSecondaryLinkClick(e));
    this.$secondaryNavBackBtn.on(EVENTS.CLICK, () => this.goBack());
    this.$secondaryNavTrigger.on(EVENTS.CLICK, e => this.toggleLinkGroup(e));
    this.$searchTrigger.on(EVENTS.CLICK, e => this.closeNav(e));
    this.$secondaryNavLinks.on(EVENTS.FOCUSOUT, e => this.handleSecondaryLinkFocusOut(e));
    this.$secondaryNavLinks.on(EVENTS.KEYDOWN, e => this.trackKey(e));
    this.$primaryNavBtns.on(EVENTS.FOCUSOUT, e => this.handlePrimaryButtonFocusOut(e));
    this.$primaryNavBtns.on(EVENTS.KEYDOWN, e => this.trackKey(e));

    this.$window.on('resize', () => { document.documentElement.clientWidth >= 992 && document.getElementsByClassName("navBar_active").length > 0 && this.toggleNav() });

    if (this.$stickyNavbar && this.$stickyNavbar.length > 0) {
      this.scrollThreshold = DEFAULT_SCROLL_DISTANCE;

      if (this.$utilityBar && this.$utilityBar.is(`:visible`)) {
        this.scrollThreshold = this.$utilityBar.height();
      } else {
        this.$body.addClass(CLASSES.BODY_STICKY_NAVBAR);
      }

      this.$window.on(EVENTS.SCROLL, e => Utils.debounce(this.checkScrollPosition(e)));
    }

    return this;
  }

  /**
   * [trackKey handles tracking what key was pressed for when we create a focusout trap for the nav]
   * @param  {[type]} event [keydown event]
   * @return {[type]}       [void]
   */
  trackKey(event) {
    this.tabbed = Utils.isTab() || Utils.isTabBack();
  }

  handleSecondaryLinkClick(event) {
    event.stopPropagation();
  }

  handleSecondaryLinkFocusOut(event) {
    const $link = $(event.currentTarget);
    const $group = $link.closest(SELECTORS.SECONDARY_NAV);
    const isLastLinkInGroup = $link.get(0) === $group.find(SELECTORS.SECONDARY_NAV_LINK).last().get(0);
    const isFirstLinkInGroup = $link.get(0) === $group.find(SELECTORS.SECONDARY_NAV_LINK).first().get(0);
    const isLeavingNav = $(event.relatedTarget).closest(SELECTORS.SECONDARY_NAV_GROUP).length === 0;
    const focusToTrigger = () => {
      if (this.tabbed) {
        this.$primaryNavBtns
          .filter(`[data-navgroup="${$group.data('navgroup')}"]`)
          .focus();
      }
    }

    if (isLastLinkInGroup && isLeavingNav) {
      focusToTrigger();
    } else if (isFirstLinkInGroup && isLeavingNav) {
      focusToTrigger();
    }
  }

  handlePrimaryButtonFocusOut(event) {
    const $button = $(event.currentTarget);
    const $group = $(SELECTORS.SECONDARY_NAV_DESKTOP)
      .find(SELECTORS.SECONDARY_NAV)
      .filter(`[data-navgroup="${$button.data('navgroup')}"]`);
    const isOpenGroup = $group.hasClass('secondaryNav_open');

    if (isOpenGroup && Utils.isTab(this.$lastKeyEvent)) {
      $group.find(SELECTORS.SECONDARY_NAV_LINK).first().focus();
    }
  }

  handleClick(event) {
    this.$body.trigger('navigation-trigger');
  }

  /**
   * Toggles an element's data-open value to true or false
   *
   *
   * @method toggleOpen
   * @returns {Boolean}
   * @param {Element} $el [Element that will be updated]
   */
  toggleOpen($el) {
    const isOpen = $el.data(DATA.OPEN) === true;

    $el.data(DATA.OPEN, !isOpen);

    return !isOpen;
  }

  /**
   * Resets nav and its children to default state
   *
   *
   * @method closeNav
   * @public
   */
  closeNav(event) {
    const notInElement = $(event.target).closest(`${SELECTORS.NAV}`).length === 0;
    const notConditions = $(event.target).closest(`.${CLASSES.CONDITIONS}`).length === 0;
    const notMobileTrigger = $(event.target).closest(`.${CLASSES.NAV_BAR_BUTTON}`).length === 0;
    const notMobileSearch = $(event.target).closest(`.${CLASSES.SEARCH}`).length === 0;
    const mobileOpen = $(event.target).closest(`.${CLASSES.NAV_OPEN}`).length > 0;

    if (!Utils.isLargeDesktopViewport()) {
      if (notInElement && notMobileTrigger && notMobileSearch && mobileOpen) {
        this.toggleNav();
      }
    } else {
      if (notInElement && notConditions) {
        this.resetNavItems();
        this.togglePrimaryNav(false);
        this.$secondaryNav.removeClass(CLASSES.SECONDARY_NAV_OPEN);
        this.$element.data(DATA.ACTIVE_ITEM, '');
      }
    }
  }

  /**
   * Opens/closes Primary Nav based on [data-open] attribute on this.$nav
   * Also hides other DOM elements (main, footer) when nav is open
   *
   * @method toggleNav
   */
  toggleNav() {
    const navOpen = this.toggleOpen(this.$element);

    this.$element.toggleClass(CLASSES.NAV_OPEN, navOpen);
    this.$navBar.toggleClass(CLASSES.NAV_BAR_ACTIVE, navOpen);
    this.togglePrimaryNav(navOpen);
    if (!navOpen) {
      this.$secondaryNav.removeClass(CLASSES.SECONDARY_NAV_OPEN);
      this.$utilityLinks.removeClass(CLASSES.UTILITY_LINKS_ACTIVE);
      this.$element.data(DATA.ACTIVE_ITEM, '');
      this.$primaryNav.toggleClass(CLASSES.PRIMARY_NAV_ACTIVE, false);
      this.resetNavItems();
      this.toggleSecondaryNav(false);
    }

    setTimeout(() => {
      this.$body.toggleClass(CLASSES.BODY_FIXED, navOpen);
    }, 200);
  }

  /**
   * Opens/closes Primary Nav based on [data-open] attribute
   *
   * @method togglePrimaryNav
   * @param {boolean} navOpen [Flag for whether the primary nav is open]
   */
  togglePrimaryNav(navOpen) {
    this.$primaryNav.toggleClass(CLASSES.PRIMARY_NAV_OPEN, navOpen);
    this.$utilityLinks.toggleClass(CLASSES.UTILITY_LINKS_ACTIVE, navOpen);
    if (!navOpen) this.$primaryNav.removeClass(CLASSES.PRIMARY_NAV_ACTIVE);
  }

  /**
   * Opens/closes Secondary Nav based on [data-open] attribute
   *
   * @method toggleSecondaryNav
   * @param {boolean} secondaryNavOpen [Flag for whether the secondary nav is open]
   */
  toggleSecondaryNav(isOpen) {
    this.$secondaryNav.toggleClass(CLASSES.SECONDARY_NAV_OPEN, isOpen);
  }

  /**
   * Expands/Collapses the link groups within the secondary navigation
   *
   * @method toggleLinkGroup
   * @param {Event} e [Event from clicking on link group]
   */
  toggleLinkGroup(e) {
    const $group = $(e.target).closest(SELECTORS.SECONDARY_NAV_GROUP);
    const groupOpen = this.toggleOpen($group);
    $group.toggleClass(CLASSES.SECONDARY_NAV_GROUP_OPEN, groupOpen);
    $group.find(SELECTORS.SECONDARY_NAV_BTN_ICON_OPEN).toggleClass(CLASSES.ICON_HIDE, !groupOpen);
    $group.find(SELECTORS.SECONDARY_NAV_BTN_ICON_CLOSED).toggleClass(CLASSES.ICON_HIDE, groupOpen);
  }

  /**
   * Removes the active class on all primary nav items
   *
   * @method resetNavItems
   */
  resetNavItems() {
    this.$primaryNavItems.removeClass(CLASSES.PRIMARY_NAV_ITEM_ACTIVE);
  }

  /**
   * Actions that happen after choosing a primary navigation option
   *
   * @method setActiveNavItem
   * @param {Element} activeNav [The active primaryNav-item in the primaryNav]
   * @param {boolean} secondaryNavOpen [Flag for whether the secondary nav is open]
   */
  setActiveNavItem(e) {
    this.$primaryNav.toggleClass(CLASSES.PRIMARY_NAV_ACTIVE, true);
    this.resetNavItems();
    const target = e.currentTarget;
    const $activeNav = $(target).closest(SELECTORS.PRIMARY_NAV_ITEM);
    const activeNavGroup = $(target).data(DATA.NAV_GROUP);
    const notActiveItem = !this.$element.data(DATA.ACTIVE_ITEM).includes(activeNavGroup);

    this.$element.data(DATA.ACTIVE_ITEM, notActiveItem ? activeNavGroup : '');

    this.$secondaryNav.removeClass(CLASSES.SECONDARY_NAV_OPEN);
    if (notActiveItem) {
      this.$utilityLinks.removeClass(CLASSES.UTILITY_LINKS_ACTIVE);
      $activeNav.addClass(CLASSES.PRIMARY_NAV_ITEM_ACTIVE);
      this.swapSecondaryNav(activeNavGroup);
    }
  }

  /**
   * Allows user to go back to main navigation from secondary navigation
   * Mobile functionality**
   *
   * @method goBack
   */
  goBack() {
    this.$element.data(DATA.ACTIVE_ITEM, '');
    this.$primaryNav.toggleClass(CLASSES.PRIMARY_NAV_ACTIVE, false);
    this.resetNavItems();
    this.toggleSecondaryNav(false);
    this.$utilityLinks.addClass(CLASSES.UTILITY_LINKS_ACTIVE);
  }

  /**
   * Swaps out Secondary Nav content
   *
   * @method swapSecondaryNav
   * @param {Element} $activeGroup [The active primaryNav-item in the primaryNav]
   */
  swapSecondaryNav($activeGroup) {
    this.$secondaryNav.each((i, nav) => {
      const $focusedNav = $(nav);
      if ($focusedNav.data(DATA.NAV_GROUP) === $activeGroup) {
        $focusedNav.addClass(CLASSES.SECONDARY_NAV_OPEN);
        this.focusLink($focusedNav);
      }
    });
  }

  /**
   * When a secondary nav is opened, the first link will be focused
   *
   * @method focusLink
   * @param {Element} $focusedNav [The nav group that will be used to focus]
   */
  focusLink($focusedNav) {
    const $firstLink = $focusedNav.find(SELECTORS.SECONDARY_NAV_LINK).first();

    if ($firstLink) {
      $firstLink.focus();
    }
  }

  /**
   * Handles scroll position
   */
  checkScrollPosition(e) {
    const current = this.$window.scrollTop();

    if (this.$stickyNavbar) {
      if (current < this.scrollThreshold && this.$body.hasClass(CLASSES.BODY_STICKY_NAVBAR)) {
        this.$body.css(`padding-top`, ``);
        this.$body.removeClass(CLASSES.BODY_STICKY_NAVBAR);
      } else if (current >= this.scrollThreshold && !this.$body.hasClass(CLASSES.BODY_STICKY_NAVBAR)) {
        this.$body.css(`padding-top`, this.scrollThreshold);
        this.$body.addClass(CLASSES.BODY_STICKY_NAVBAR);
      }
    }
  }
}
