/*
 * @fileOverview ProductComparisonView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'URL': '.js-product-url-',
  'NAME': '.js-product-name-',
  'TEXT': '.js-attribute-text',
  'IMAGE': '.js-attribute-image',
  'ROW': '.js-attribute',
  'AFFIRMATION_CONTAINER': '.js-product-affirmation-',
  'SELECTOR': '.js-product-selector',
  'DROPDOWN': '.js-dropdown-trigger',
  'DROPDOWN_TEXT': '.js-dropdown-activeText',
  'NAV': '.js-comparison-nav'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CHANGE': 'change',
  'SCROLL': 'scroll',
  'RESIZE': 'resize'
};

/**
 * A reference to the classes used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const CLASSES = {
  'FIXED_NAV': 'comparisonNav'
};

/**
 * A reference to the constants used in this view
 *
 * @property CONSTANTS
 * @type {Object}
 * @private
 */
const CONSTANTS = {
  'NAV_OFFSET': 160
};


/**
 * Handles populating the comparison grid when changing selection
 *
 * @class ProductComparisonView
 */
export default class ProductComparisonView extends View {

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {ProductComparisonView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$selectors = this.$element.find(SELECTORS.SELECTOR);
    this.$rows = this.$element.find(SELECTORS.ROW);
    this.$nav = this.$element.find(SELECTORS.NAV);

    //the data written to the page from the server
    this.productComparisonData = productComparison;

    this.$window = $(window);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ProductComparisonView}
   * @chainable
   * @public
   */
  enable() {
    this.$selectors.on(EVENTS.CHANGE, e => this.handleSelectorChange(e));
    const scrollCallback = Utils.throttle(e => this.handleScroll(e), 100);

    $(window).on(EVENTS.SCROLL, scrollCallback);
    $(window).on(EVENTS.RESIZE, scrollCallback);
    return this;
  }

  /**
   * Scroll handler to determine how to display the Comparison Dropdowns. Fixed or inline
   */
  handleScroll() {
    if (this.$element.offset().top + this.$element.height() < this.$window.scrollTop()) {
      this.ToggleNavState(false);
    }
    else if (this.$element.offset().top + CONSTANTS.NAV_OFFSET < this.$window.scrollTop()) {
      this.ToggleNavState(true);
    }
    else {
      this.ToggleNavState(false);
    }
  }

  /**
   * Adds appropriate classes to Comparison Dropdown Navigation bar
   * @param {boolean} isFixed
   */
  ToggleNavState(isFixed) {
    if (isFixed === this.isFixed) return;

    if (isFixed) {
      this.$nav.addClass(CLASSES.FIXED_NAV);
    }
    else {
      this.$nav.removeClass(CLASSES.FIXED_NAV);
    }
    this.isFixed = isFixed;
  }

  /**
   * Handles showing the correct product information across all rows and columns, ensuring no duplicates
   * @param {event} e
   */
  handleSelectorChange(e) {
    const $input = $(e.target);
    const product = $input.val();
    const column = $input.attr('data-which-selector');

    this.showProduct(product, column);
    this.ensureNoDuplicates($input, product);
  }

  /**
   * Handles showing the correct product information in a single column
   * @param {string} productName - the name of the product
   * @param {int} column - the column number, 1 based not 0
   */
  showProduct(productName, column) {
    const product = this.productComparisonData.Products.find(element => {
      return element.Name === productName
    });

    //populate urls
    this.$element.find(`${SELECTORS.URL}${column}`).attr('href', product.PurchaseUrl);
    //populate names
    this.$element.find(`${SELECTORS.NAME}${column}`).text(product.Name);

    this.populateRows(product, column);
  }

  /**
   * Loops across the attributes in the product to determine if it has them
   * @param {object} product - the product class with attributes set
   * @param {int} column - the column number, 1 based not 0
   */
  populateRows(product, column) {
    this.$rows.each((idx, item) => {
      const $item = $(item);
      const attributeType = $item.attr('data-attribute-type');

      const attribute = product.Attributes.find(element => {
        return element.Slug === attributeType
      });

      if (attribute) {
        //handles text override at the product level such as price
        if (attribute.TextOverride) {
          this.populateText($item, column, attribute.TextOverride);
        }
        else {
          if (this.productComparisonData.PositiveText) {
            this.populateText($item, column, this.productComparisonData.PositiveText);
          }
          else {
            this.populateImage($item, column, this.productComparisonData.PositiveImage);
          }
        }
      } else {
        if (this.productComparisonData.NegativeText) {
          this.populateText($item, column, this.productComparisonData.NegativeText);
        } else {
          this.populateImage($item, column, this.productComparisonData.NegativeImage);
        }
      }
    });
  }

  /**
   * Handles showing the text for an attribute
   * @param {jquery object} $row - the container of the data
   * @param {int} column
   * @param {string} text
   */
  populateText($row, column, text) {
    $row.find(`${SELECTORS.AFFIRMATION_CONTAINER}${column}`).find(SELECTORS.TEXT).text(text);
  }

  /**
   * Handles showing the image for an attribute
   * @param {jquery object} $row - the container of the data
   * @param {int} column
   * @param {string} src - the image src, expect an SVG
   */
  populateImage($row, column, src) {
    $row.find(`${SELECTORS.AFFIRMATION_CONTAINER}${column}`).find(SELECTORS.IMAGE).attr('src', src);
  }

  /**
   * Ensures that two columns do not show the same product. If someones selects the same one, we swap the columns
   * @param {jquery object} $input - the dropdown input that changed
   * @param {string} productName - the name of the product
   */
  ensureNoDuplicates($input, productName) {
    const previousProductName = $input.attr('data-product');

    const $toChange = this.$element.find(`input[value="${productName}"]`).not(`[data-product="${previousProductName}"]`);

    if ($toChange.length > 0) {
      this.changeProduct(previousProductName, $toChange);
    }

    $input.attr('data-product', productName);
  }

  /**
   * Handles changing a product from one to the other by code
   * @param {object} product - the product object with attributes
   * @param {jquery object} $toChange - the input dropdown to change
   */
  changeProduct(product, $toChange) {
    $toChange.val(product);
    $toChange.attr('data-product', product);

    const $text = $toChange.siblings(SELECTORS.DROPDOWN).find(SELECTORS.DROPDOWN_TEXT);

    if ($text.length > 0) {
      $text.text(product);
    }

    this.showProduct(product, $toChange.attr('data-which-selector'))
  }
}
