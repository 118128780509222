/*
 * @fileOverview Quarantine module definition
 */

import View from 'views/View';
import TableView from 'views/TableView'
import ScrollTableView from 'views/ScrollTableView'

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'SCROLL_TABLE': '.tableScroller'
};

/**
 * Sets up tables for QuarantineView
 *
 * @class QuarantineView
 */
export default class QuarantineView extends View {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {SearchView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$tables = this.$element.find('table').not(SELECTORS.SCROLL_TABLE);
    this.$scrollTables = this.$element.find(SELECTORS.SCROLL_TABLE);

    return this;
  }

  /*
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit() {
    this.initTables();
    return this;
  }
  /*
  * Loop across all and initilize them
  *
  * @property initTables
  * @returns {void}
  * @public
  */
  initTables() {
    this.$tables.each((idx, element) => {
      if (!$(element).hasClass('no-js')) {
        var table = new TableView(element);
      }
    });

    this.$scrollTables.each((idx, element) => {
      if (!$(element).hasClass('no-js')) {
        var table = new ScrollTableView(element);
      }
    });
  }
}
