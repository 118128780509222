/*
 * @fileOverview DarkSkyIconView module definition
 */

import View from 'views/View';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'WEATHER_NAV': '.js-weather-nav',
  'WEATHER_PAGE': '.js-weather-page'
};

const DATA = {
  'WEATHER_TYPES': [
    'clear',
    'clear-day',
    'clear-night',
    'partly-cloudy',
    'partly-cloudy-day',
    'partly-cloudy-night',
    'cloudy',
    'cloudy',
    'cloudy-day',
    'rain',
    'sleet',
    'snow',
    'wind',
    'fog'
  ]
}

/**
 * Starts dark sky icons
 *
 * @class DarkSkyIconView
 */
export default class DarkSkyIconView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {PageContentNavView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$weatherNav = this.$element.find(SELECTORS.WEATHER_NAV);
    this.$weatherPage = this.$element.find(SELECTORS.WEATHER_PAGE);

    return this;
  }

  /*
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit() {
    //need to run only once on page regardless of how many modules might be added
    if (window.isSkyConsInitialized) return;
    window.isSkyConsInitialized = true;

    this.renderNavIcons();
    this.renderPageIcons();
  }

  /**
   * Render icons in nav
   *
   * @method renderNavIcons
   * @public
   */
  renderNavIcons() {
    const color = this.$weatherNav.css('fill');
    const icons = new Skycons({'color': color});

    for (let weatherType of DATA.WEATHER_TYPES) {
      let elements = document.getElementsByClassName(weatherType);

      for (let icon of elements) {
        icons.set(icon, weatherType);
      }
    }

    icons.play();
  }

  /**
   * Render any icons outside of the nav
   *
   * @method renderPageIcons
   * @public
   */
  renderPageIcons() {
    const color = this.$weatherPage.css('fill');
    const pageIcons = new Skycons({'color': color});

    for (let weatherType of DATA.WEATHER_TYPES) {
      let elements = document.getElementsByClassName(`page-${weatherType}`);

      for (let icon of elements) {
        pageIcons.set(icon, weatherType);
      }
    }

    pageIcons.play();
  }
}
