
/*
 * @fileOverview DatepickerView module definition
 */

import DatepickerView from 'views/DatepickerView';
import moment from 'moment';

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'CELL_ACTIVE': 'datepicker-cell_active',
  'DISPLAY_ACTIVE': 'btn_displayInputActive'
};

/**
 * Toggles subNav height and aria roles in an DatepickerView view component
 *
 * @class DatepickerView
 */
export default class SingleDatepickerView extends DatepickerView {
  /*
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit () {
    this.closestParent = '[data-module="single-datepicker"]';

    super.afterInit();

    return this;
  }
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {DatepickerView}
   * @public
   * @chainable
   */
  createChildren() {
    this.minimumMonthsShown = 1;

    super.createChildren();

    return this;
  }

  /**
   * Callback for jQueryDatepicker's beforeShowDay hook
   *
   * Applies css class to selected dates and
   * dates within the selected range highlighting
   * them visibly for the user
   *
   * @method highlightCells
   * @returns {[shouldDisplayCell, cssClass]}
   * @public
   */
  highlightCells(date) {
    const cellDate = new Date(date);
    const cssClass = moment(cellDate).isSame(this.startDate) ? CLASSES.CELL_ACTIVE : '';

    return [true, cssClass];
  }

  /**
   * Sets internal variables for tracking start & end dates
   * Will reset variables if both are already selected
   * Will enforce valid selection
   *
   * @method setInternalDates
   * @public
   */
  setInternalDates(date) {
    this.startDate = moment(new Date(date));
  }

  /**
   * Updates display input
   *
   * @method updateDisplay
   * @public
   */
  updateDisplay() {
    const start = this.startDate ? moment(this.startDate).format('MMM D') : 'Check In';

    this.$displayInput.text(start).addClass(CLASSES.DISPLAY_ACTIVE);
  }
}
