/*
 * @fileOverview AccordionView module definition
 */

import View from './View';
import { TweenMax, Power1 } from 'views/GsapLoader';

console.log(Power1);
/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'TRIGGER': '.js-accordion-trigger',
  'TARGET': '.js-accordion-pane',
  'ICON_PLUS': '.js-plus',
  'ICON_MINUS': '.js-minus',
  'OPEN_ALL': '.js-open-all',
  'OPEN_TEXT': '.js-open-text'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'PANE_ACTIVE': 'js-accordion-pane_active',
  'ICON_HIDDEN': 'mix-icon_hide'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

/**
 * A reference to the animation settings used in this view
 *
 * @property ANIMATION
 * @type {Object}
 * @private
 */
const ANIMATION = {
  'TIME': 0.5,
  'TIME_SCROLL': 1000,
  'EASE': Power1.easeOut,
  'EASE_OUT': Power1.easeIn
};

/**
 * Handles accordion open and close functionality
 *
 * @class AccordionView
 */
export default class AccordionView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {AccordionView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$body = $('html,body');
    this.$triggers = this.$element.find(SELECTORS.TRIGGER);
    this.$panes = this.$element.find(SELECTORS.TARGET);
    this.$openAll = this.$element.find(SELECTORS.OPEN_ALL);
    this.$openText = this.$element.find(SELECTORS.OPEN_TEXT);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {AccordionView}
   * @chainable
   * @public
   */
  enable() {
    this.$triggers.on(EVENTS.CLICK, e => this.handleTriggerClick(e));
    this.$openAll.on(EVENTS.CLICK, e => this.handleOpenAll(e));

    return this;
  }

  /**
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @method afterInit
   * @returns {AccordionView}
   * @public
   */
  afterInit() {
    this.isOpenAll = false;

    this.checkForNestedLinks();

    if (this.isDeepLinked()) {
      this.openLinkedItem();
    }
  }

  /**
   * Calls open close methods depending on state
   *
   * @method handleTriggerClick
   * @param {Object} event [Javascript event object]
   * @public
   */
  handleTriggerClick(e) {
    const $trigger = $(e.currentTarget);
    const $pane = $trigger.next(SELECTORS.TARGET);

    if ($trigger.hasClass(CLASSES.PANE_ACTIVE)) {
      this.close($trigger, $pane);
    } else {
      this.open($trigger, $pane);
    }
  }

  /**
   * Calls open/close all depending on state
   *
   * @method handleOpenAll
   * @param {Object} event [Javascript event object]
   * @public
   */
  handleOpenAll() {
    if (!this.isOpenAll) {
      this.openAll();
    } else {
      this.closeAll();
    }
  }

  /**
   * Opens clicked accordion item
   *
   * @method open
   * @param {jQuery} $trigger
   * @param {jQuery} $pane
   * @public
   */
  open($trigger, $pane) {
    TweenMax.to($pane, ANIMATION.TIME, {
      'height': $pane.get(0).scrollHeight,
      'onStart': function() {
        $trigger.find(SELECTORS.ICON_PLUS).addClass(CLASSES.ICON_HIDDEN);
        $trigger.find(SELECTORS.ICON_MINUS).removeClass(CLASSES.ICON_HIDDEN);
        $trigger.addClass(CLASSES.PANE_ACTIVE);
      },
      'ease': ANIMATION.EASE,
      'onComplete': () => {
        $trigger.attr('aria-expanded', true);
        $pane.attr('aria-hidden', false);
        $pane.find('a').attr('tabIndex', 0);

        this.checkOpenState();
      }
    });
  }

  /**
   * Closes clicked accordion item
   *
   * @method close
   * @param {jQuery} $trigger
   * @param {jQuery} $pane
   * @public
   */
  close($trigger, $pane) {
    TweenMax.to($pane, ANIMATION.TIME, {
      'height': 0,
      'onStart': function() {
        $trigger.find(SELECTORS.ICON_PLUS).removeClass(CLASSES.ICON_HIDDEN);
        $trigger.find(SELECTORS.ICON_MINUS).addClass(CLASSES.ICON_HIDDEN);
      },
      'ease': ANIMATION.EASE_OUT,
      'onComplete': () => {
        $trigger.attr('aria-expanded', false);
        $pane.attr('aria-hidden', true);
        $trigger.removeClass(CLASSES.PANE_ACTIVE);
        $pane.find('a').attr('tabIndex', -1);

        this.checkOpenState();
      }
    });
  }

  /**
   * Opens all accordions items
   *
   * @method openAll
   * @public
   */
  openAll() {
    this.$openText.text('Collapse All');
    this.isOpenAll = true;

    this.$triggers.each((index, el) => {
      const $trigger = $(el);
      const $pane = $trigger.next(SELECTORS.TARGET);

      this.open($trigger, $pane);
    });
  }

  /**
   * Closes all accordions items
   *
   * @method closeAll
   * @public
   */
  closeAll() {
    this.$openText.text('Expand All');
    this.isOpenAll = false;

    this.$triggers.each((index, el) => {
      const $trigger = $(el);
      const $pane = $trigger.next(SELECTORS.TARGET);

      this.close($trigger, $pane);
    });
  }

  /**
   * Checks if deep link slug is in querystring
   *
   * @method isDeepLinked
   * @returns {Boolean}
   * @public
   */
  isDeepLinked() {
    return window.location.href.search("[?&]tab=") !== -1;
  }

  /**
   * Calls open on deep linked item
   *
   * @method openLinkedItem
   * @public
   */
  openLinkedItem() {
    const slug = this.getQueryString('tab');
    const $trigger = $(`[data-deep-link="${slug}"]`);
    const $pane = $trigger.next(SELECTORS.TARGET);

    if ($trigger.length > 0) {
      this.open($trigger, $pane);
      this.scrollToTrigger($trigger);
      $trigger.focus();
    }
  }

  /**
   * Get the value of a querystring parameter
   *
   * @param {String} field
   * @returns {String}
   * @public
   */
  getQueryString(field) {
    const href = window.location.href;
    const reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
    const string = reg.exec(href);

    return string ? string[1] : null;
  }

  /**
   * Scrolls down to deep linked trigger
   *
   * @method scrollToTrigger
   * @public
   */
  scrollToTrigger($trigger) {
    const triggerPosition = $trigger.offset().top;

    this.$body.animate({
       'scrollTop': triggerPosition
     }, ANIMATION.TIME_SCROLL);
  }

  /**
   * Sets nested links tabIndex to -1
   *
   * @method checkForNestedLinks
   * @public
   */
  checkForNestedLinks() {
    this.$panes.find('a').attr('tabIndex', -1);
  }

  /**
   * Checks if all panes are open or closed
   *
   * @method checkOpenState
   * @public
   */
  checkOpenState() {
    const totalPanes = this.$triggers.length;
    const totalOpen = this.$triggers.filter(`.${CLASSES.PANE_ACTIVE}`).length;

    if (totalOpen === 0 && this.isOpenAll) {
      this.$openText.text('Expand All');
      this.isOpenAll = false;
    } else if (totalOpen === totalPanes && !this.isOpenAll) {
      this.$openText.text('Collapse All');
      this.isOpenAll = true;
    }
  }
}
