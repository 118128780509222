/*
 * @fileOverview PageContentNavView module definition
 */

import View from 'views/View';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'LINK': '.js-contentNavLink'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

/**
 * A reference to the animation settings used in this view
 *
 * @property ANIMATION
 * @type {Object}
 * @private
 */
const ANIMATION = {
  'TIME_SCROLL': 1000
};
/**
 * Scrolls to sections on the page when a PageContentNav
 * link is clicked or was deep linked too
 *
 * @class PageContentNavView
 */
export default class PageContentNavView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {PageContentNavView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$body = $('html,body');
    this.$links = this.$element.find(SELECTORS.LINK);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {PageContentNavView}
   * @chainable
   * @public
   */
  enable() {
    this.$links.on(EVENTS.CLICK, e => this.handleLinkClick(e));

    return this;
  }

  afterInit() {
    this.scrollIfDeepLinked();
  }

  scrollIfDeepLinked() {
    const sectionId = window.location.hash;
    if (!sectionId) return;

    setTimeout(() => {
      this.scrollToSection(sectionId);
    }, 1000)
  }

  handleLinkClick(e) {
    e.preventDefault();
    const sectionId = e.target.hash;

    this.scrollToSection(sectionId);
  }

  scrollToSection(sectionId) {
    const section = document.querySelector(sectionId);
    if (!section) return;
    const position = $(section).offset().top;

    this.$body.animate({
      'scrollTop': position
    }, ANIMATION.TIME_SCROLL);
  }
}
