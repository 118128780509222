/*
 * @fileOverview ProductDetailsView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'CHECKBOX_INPUT': '.js-product-compare',
  'CHECKBOX': '.js-checkbox'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'RESIZE': 'resize'
};

/**
 * Handles populating the comparison grid when changing selection
 *
 * @class ProductDetailsView
 */
export default class ProductDetailsView extends View {

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {ProductDetailsView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$checkboxes = this.$element.find(SELECTORS.CHECKBOX_INPUT);
    this.selectedCount = 0;

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ProductDetailsView}
   * @chainable
   * @public
   */
  enable() {
    this.$checkboxes.on(EVENTS.CLICK, e => this.handleClick(e));
    const resizeCallback = Utils.throttle(e => this.handleResize(e), 100);

    $(window).on(EVENTS.RESIZE, resizeCallback);
    return this;
  }

  /**
   * Handles the resizing of the window. The number of items allowed changes depending on window size
   * @param {event} e
   */
  handleResize(e) {
    //if there are three selected then remove that one and disable
    if (this.selectedCount > this.getMax()) {
      const $last = this.$checkboxes.filter(':checked').last();
      $last.prop('checked', false);
      $last.prop('disabled', true);
      this.selectedCount--;
    }

    this.checkAllowances();
  }

    /**
     * Handles the click of a checkbox.
     * @param {event} e
     */
  handleClick(e) {
    const $input = $(e.target);
    if ($input[0].checked) {
      this.selectedCount++;
    }
    else {
      this.selectedCount--;
    }

    this.checkAllowances();
  }

    /**
     * Toggles disabled state depending on selected count
     */
  checkAllowances() {
    if (this.selectedCount >= this.getMax()) {
      this.disallowSelections();
    } else {
      this.allowSelections();
    }
  }

    /**
     * Return the max allowed selection based on viewport size
     */
  getMax() {
    return Utils.isDesktopViewport() ? 3 : 2;
  }

    /**
     * Removes disabled state from checkboxes
     */
  allowSelections() {
    this.$checkboxes.prop('disabled', false);
  }

    /**
     * Disables unchecked checkboxes
     */
  disallowSelections() {
    this.$checkboxes.not(':checked').prop('disabled', true);
  }
}
