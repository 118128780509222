/*
 * @fileOverview FeaturedDropdownView module definition
 */

import DropdownView from 'views/dropdowns/DropdownView';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'HIDDEN_INPUT': '.js-dropdown-hidden'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'FEATURE_GROUP_ACTIVE': 'featureGroup_active'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'CHANGE': 'change'
};

/**
 * View for updating dropdown values in forms
 *
 * @class FormDropdownView
 */
export default class FormDropdownView extends DropdownView {
    constructor($element) {
      super($element);
    }
  /**
   * Swaps out featured cards based on selection
   *
   * @param {Event} e [The event from making a selection in the dropdown]
   * @method updateAction
   */
  updateAction(e) {
    const option = $(e.currentTarget).data('option');

    this.toggleDropdown();
    this.updateTriggerText(option);
    this.updateFormInput(option);
  }

  /**
   * Finds the hidden input inside the form dropdown and updates it so the new
   * selected value gets passed by the form.
   *
   * @method updateFormInput
   * @param {String} option [The value of the option selected in the dropdown]
   */
  updateFormInput(option) {
    var $input = this.$element.find(SELECTORS.HIDDEN_INPUT);
    $input.val(option);
    $input.trigger(EVENTS.CHANGE, option);
  }
}
