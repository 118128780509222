/*
 * @fileOverview ModalView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';
import { TweenMax, Power1, Expo } from 'views/GsapLoader';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'MODAL_CLOSE': '.js-modal-close',
  'MODAL_CLOSE_MOBILE': '.js-modal-close_mobile',
  'MODAL_INNER': '.js-modal-inner',
  'AFTER_CONTENT_TRAP': '.js-modal-afterContent',
  'BEFORE_CONTENT_TRAP': '.js-modal-beforeContent',
  'CONTENT_FOCUSABLE': '.js-modal-focusable',
  'RESET_CONTENT': '.js-modal-reset-onClose',
  'ASYNC_CONTENT': '.js-modal-async'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'NO_SCROLL': 'noScroll',
  'MODAL_ACTIVE': 'modal_active',
  'MODAL_BG': 'modal-bg'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'KEYDOWN': 'keydown',
  'BLUR': 'blur',
  'FOCUS': 'focus',
  'FOCUSOUT': 'focusout'
};

/**
 * A reference to the animation settings used in this view
 *
 * @property ANIMATION
 * @type {Object}
 * @private
 */
const ANIMATION = {
  'TIME_IN': 0.5,
  'TIME_OUT': 0.750,
  'EASE': Power1.easeOut,
  'EASE_OUT': Expo.easeInOut
};

/**
 * Modal specific interactions
 *
 * @class ModalView
 */
export default class ModalView extends View {
  constructor($element, options = {}) {
    super($element);

    this.afterHide = options.afterHide;
    this.afterShow = options.afterShow;
  }
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {ModalView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$window = $(window);
    this.$body = $('html,body');
    this.$document = $(document);
    this.modalName = this.$element.data('modalName');
    this.$modalTrigger = $(`[data-modal-trigger="${this.modalName}"]`);
    this.$close = this.$element.find(SELECTORS.MODAL_CLOSE);
    this.$modalInner = this.$element.find(SELECTORS.MODAL_INNER);
    this.$previousFocus = $();
    this.$afterContentTabTrap = this.$element.find(SELECTORS.AFTER_CONTENT_TRAP);
    this.$beforeContentTabTrap = this.$element.find(SELECTORS.BEFORE_CONTENT_TRAP);
    this.$focusableContent = this.$element.find(SELECTORS.CONTENT_FOCUSABLE);
    this.$needsReset = this.$element.find(SELECTORS.RESET_CONTENT);
    this.$asyncContent = this.$element.find(SELECTORS.ASYNC_CONTENT);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ModalView}
   * @chainable
   * @public
   */
  enable() {
    this.$body.on(EVENTS.CLICK, e => this.handleBodyClick(e));
    this.$modalTrigger.on(EVENTS.CLICK, e => this.handleTriggerClick(e));
    this.$element.on(EVENTS.KEYDOWN, e => this.setDirection(e));
    this.$close.not(SELECTORS.MODAL_CLOSE_MOBILE).first().on(EVENTS.BLUR, e => this.handleCloseBlur(e));
    this.$close.on(EVENTS.CLICK, e => this.handleCloseClick(e));
    this.$window.on(EVENTS.KEYDOWN, Utils.throttle(e => this.handleWindowKeydown(e)));
    this.$afterContentTabTrap.on(EVENTS.FOCUS, e => this.focusAfterContent());
    this.$beforeContentTabTrap.on(EVENTS.FOCUS, e => this.focusBeforeContent());
    this.$focusableContent.on(EVENTS.FOCUS, e => this.focusContent(e));

    return this;
  }

  afterInit() {
    this.scrollTop = 0;
  }

  /**
   * set the direction of the tab, forward or backward
   * @param {event} e
   */
  setDirection(e) {
    if (Utils.isTab(e)) {
      this.direction = 'forward';
    }

    if(Utils.isTabBack(e)) {
      this.direction = 'backward';
    }
  }

  /**
   * Add view show classes, perform show animations, handle focus
   *
   * @method show
   * @public
   */
  show() {
    //one modal open at a time
    if (this.$body.hasClass(CLASSES.NO_SCROLL)) return;

    this.isOpen = true;
    this.scrollTop = this.$window.scrollTop();
    this.$element.addClass(CLASSES.MODAL_ACTIVE);
    this.loadAsyncContent();

    TweenMax.fromTo(this.$element, ANIMATION.TIME_IN, {
        'opacity': 0
      },
      {
        'opacity': 1,
        'ease': ANIMATION.EASE,
        'onComplete': () => {
          this.$body.addClass(CLASSES.NO_SCROLL);
          if (this.afterShow) this.afterShow();
        }
      });

    this.$previousFocus = $(':focus');
    this.$close.last().focus();
  }

  /**
   * Remove view show classes, perform hide animations, handle focus
   *
   * @method hide
   * @public
   */
  hide() {
    this.isOpen = false;
    this.$body.removeClass(CLASSES.NO_SCROLL).scrollTop(this.scrollTop);

    TweenMax.to(this.$element, ANIMATION.TIME_OUT/2, {
      'opacity': 0,
      'ease': ANIMATION.EASE_OUT,
      'onComplete': () => {
        this.$element.removeClass(CLASSES.MODAL_ACTIVE);

        if (this.afterHide) this.afterHide();
      }
    });

    this.$previousFocus.focus();
    this.$previousFocus = $();
    this.resetContent();
  }

  /**
   * [resetContent reset any content that doesn't need to be loaded unitl modal is shown]
   */
  resetContent() {
    if (this.$needsReset.length > 0) {
      this.$needsReset.each((idx, item) => {
        const $item = $(item);
        const src = $item.attr('src');
        $item.attr('src', '');
        $item.attr('src', src);
      });
    }
  }

  /**
   * [loadAsyncContent load content once modal is shown]
   * @return {void}
   */
  loadAsyncContent() {
    if (this.$asyncContent.length > 0) {
      this.$asyncContent.each((idx, item) => {
        const $item = $(item);
        const src = $item.data('src');
        $item.attr('src', src);
      });
    }
  }

  /**
   * Blur handler for close button
   * will create focus trap, causing user
   * to close modal before moving on
   *
   * @method handleCloseBlur
   * @public
   */
  handleCloseBlur(e)  {
    if (this.direction === 'forward') {
      this.focusContent(e);
    }
    else {
      this.focusAfterContent(e);
    }
  }

  /**
   * [focusAfterContent handles what to focus on after the content or before, depending on direction]
   * @param  {event} e
   * @return {void}
   */
  focusAfterContent(e) {
    if (this.direction === 'forward') {
      this.$close.filter(':visible').first().focus();
    }
    else {
      this.focusContent(e);
    }
  }

  /**
   * [focusBeforeContent handles what to focus on after the content or before, depending on direction]
   * @param  {event} e
   * @return {void}
   */
  focusBeforeContent(e) {
    if (this.direction === 'forward') {
      this.focusContent(e);
    }
    else {
      this.$close.filter(':visible').first().focus();
    }
  }

  /**
   * [focusContent focus content or after content, or before depending on direction of tab]
   * @param  {event} e
   * @return {void}
   */
  focusContent(e) {
    if (this.direction === 'forward') {
      const $tofocus = this.$focusableContent.find('iframe, input, :focusable').first();
      if ($tofocus.length > 0) {
        $tofocus.get(0).focus();
      }
      else {
        this.focusAfterContent(e);
      }
    }
    else {
      const $tofocus = this.$focusableContent.find('iframe, input, :focusable').last();
      if ($tofocus.length > 0) {
        $tofocus.get(0).focus();
      }
      else {
        this.focusBeforeContent(e);
      }
    }
  }

  /**
   * Click handler for body clicks
   * will close modal
   *
   * @method handleBodyClick
   * @public
   */
  handleBodyClick(e) {
    if (!Utils.isDesktopViewport() || !this.isOpen) return;
    if ($(e.target).hasClass(CLASSES.MODAL_BG)) this.hide();
  }

  /**
   * Click handler for close click
   *
   * @method handleCloseClick
   * @public
   */
  handleCloseClick(e) {
    e.stopPropagation();
    this.hide();
  }

  /**
   * Click handler for modal triggers
   *
   * @method handleTriggerClick
   * @param {Event} JavaScript event object
   * @public
   */
  handleTriggerClick(event) {
    event.preventDefault();

    this.show($(event.currentTarget));
  }

  /**
   * Click handler for escape key
   *
   * @method handleWindowKeydown
   * @param {Event} JavaScript event object
   * @public
   */
  handleWindowKeydown(event) {
    if (event.keyCode === 27) {
      this.hide();
    }
  }
}
