/*
 * @fileOverview AmadeusFormView module definition
 */

import FormView from 'views/FormView';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'FAIL': '.js-fail-message',
  'SUCCESS': '.js-success-message',
  'URL': '.js-url'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'DISABLE_SUBMIT': 'btn_primaryDisabled'
};

/**
 * Submits Amadeus form to controller
 *
 * @class AmadeusFormView
 * @constructor
 */
export default class AmadeusFormView extends FormView {
    /*
    * Provides a post initialization hook for additional setup
    * outside of event and child setup
    *
    * @property afterInit
    * @returns {FormView}
    * @public
    */
   afterInit() {
     this.$fail = this.$element.find(SELECTORS.FAIL);
     this.$success = this.$element.find(SELECTORS.SUCCESS);
     this.$url = this.$element.find(SELECTORS.URL);
   }

  /**
   * Handle form submit
   *
   * @method handleFormSubmit
   * @param {Event} JavaScript event object
   * @public
   */
  handleFormSubmit(event) {
    event.preventDefault();

    if (this.processing) return false;

    this.processing = true;
    this.$submitBtn.addClass(CLASSES.DISABLE_SUBMIT);

    if (this.validateForm()) {
      this.$submitBtn.addClass(CLASSES.DISABLE_SUBMIT);
      this.submitForm();
    } else {
      this.$submitBtn.removeClass(CLASSES.DISABLE_SUBMIT);
    }

    this.processing = false;

    return false;
  }

  /**
   * Submit data to Amadeus Controller
   *
   * @method submitForm
   * @public
   */
  submitForm() {
    const data = this.convertFormToJSON();
    const settings = {
      'accept': 'application/json',
      'data': this.$element.serialize(), //'data': {"json" : JSON.stringify(data)},
      'error': (error) => {
        this.$fail.show();
        this.$submitBtn.removeClass(CLASSES.DISABLE_SUBMIT);
      },
      'success': (data) => {
        this.$fail.hide();
        this.$success.show();
      },
      'type': 'POST',
      'url': this.$url.val()
    }
    $.ajax(settings);
  }

  /**
   * Convert form data to JSON
   *
   * @method convertFormToJSON
   * @public
   */
  convertFormToJSON(){
    const array = this.$element.serializeArray();
    let json = {};

    $.each(array, function() {
    	json[this.name] = this.value || '';
    });

    return json;
  }
}
