/*
 * @fileOverview GoogleTranslateView module definition
 */

import FormDropdownView from 'views/dropdowns/FormDropdownView';
import CookieService from 'services/CookieService';

/**
 * A reference to the date attributes used in this view
 *
 * @property DATA
 * @type {Object}
 * @private
 */
const DATA = {
  'LANGUAGE': 'data-language'
};

/**
 * Toggles the Language in an GoogleTranslateView view component
 *
 * @class GoogleTranslateView
 */
export default class GoogleTranslateView extends FormDropdownView {
  constructor($element) {
    super($element);
    this.showSelectedLanguage();
  }

  /**
   * Show the correct language in the Google Translate dropdown depending on the cookie value
   */
  showSelectedLanguage() {
    this.language = this.getCurrentLanguage();
    if (this.language) {
      const elem = this.$element.find(`[${DATA.LANGUAGE}="${this.language}"]`);
      if (elem && elem.length > 0) {
        this.updateTriggerText(elem.text());
      }
    }
  }

  /**
   * get the current language from cookie, and format correctly for our app
   */
  getCurrentLanguage() {
    let language = CookieService.getCookie('googtrans');
    if (!language) return null;

    if (language.startsWith("/")) {
      language = language.slice(1);
    }

    return language.replace("/", "|");
  }

  /**
   *
   * @param {object} e the clicked element, the googletranslate button
   */
  updateAction(e) {
    super.updateAction(e);

    const dropdown = e.currentTarget;
    const language = dropdown.getAttribute(DATA.LANGUAGE);

    if (this.language === language || (!this.language && language.includes('en|en'))) return false;

    this.language = language;
    doGTranslate(language);
    return false;
  }
}
