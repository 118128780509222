/*
 * @fileOverview GuestNumberView module definition
 */

import View from 'views/View';
import MobileFlyoutView from 'views/MobileFlyoutView';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'DISPLAY_INPUT': '.js-displayInput',
  'NUMBER_PICKER': '.js-guestNumberPicker',
  'ADULT_INPUT': '.js-adultCountInput',
  'JUNIOR_INPUT': '.js-juniorCountInput',
  'KID_INPUT': '.js-kidCountInput',
  'ADULT_DISPLAY': '.js-adultCountDisplay',
  'JUNIOR_DISPLAY': '.js-juniorCountDisplay',
  'KID_DISPLAY': '.js-kidCountDisplay',
  'MINUS_BUTTON': '.js-minusButton',
  'PLUS_BUTTON': '.js-plusButton',
  'CLOSE_BUTTON': '.js-closeButton',
  'CLOSE_ICON': '.js-closeIcon'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'HIDDEN': 'isVisuallyHidden',
  'DISPLAY_ACTIVE': 'btn_displayInputActive',
  'ICON_UP': 'mix-icon_rotate270',
  'ICON_DOWN': 'mix-icon_rotate90'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'FOCUS': 'focus',
  'BLUR': 'blur',
  'CLICK_FOCUS': 'click focus',
  'KEYDOWN': 'keydown'
};

/**
 * A reference to the data attributes used in this view
 *
 * @property DATA
 * @type {Object}
 * @private
 */
const DATA = {
  'TEXT': 'input-text',
  'PLURALTEXT': 'input-text-plural',
  'DEFAULTCOUNT': 'default-count'
};

/**
 * Hangles widget to allow user to select the Number of Guests for an events
 * Default is 1 Adult
 *
 * @class GuestNumberView
 */
export default class GuestNumberView extends View {
  /*
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit () {
    this.kidCount = 0;
    this.adultCount = 0;
    this.juniorCount = 0;

    if (this.$kidInput.length > 0) {
      this.kidCount = Number(this.$kidInput.data(DATA.DEFAULTCOUNT));
    }

    if (this.$adultInput.length > 0) {
      this.adultCount = Number(this.$adultInput.data(DATA.DEFAULTCOUNT));
    }

    if (this.$juniorInput.length > 0) {
      this.juniorCount = Number(this.$juniorInput.data(DATA.DEFAULTCOUNT));
    }

    this.singularText = this.$displayInput.data(DATA.TEXT);
    this.pluralText = this.$displayInput.data(DATA.PLURALTEXT);

    this.closestParent = '[data-module="guest-number-picker"]';

    return this;
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {GuestNumberView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$numberPickerFlyout = new MobileFlyoutView(this.$element.find(SELECTORS.NUMBER_PICKER), {
      'afterShow': this.afterShow.bind(this),
      'afterHide': this.afterHide.bind(this)
    });
    this.$displayInput = this.$element.find(SELECTORS.DISPLAY_INPUT);
    this.$adultInput = this.$element.find(SELECTORS.ADULT_INPUT);
    this.$kidInput = this.$element.find(SELECTORS.KID_INPUT);
    this.$juniorInput = this.$element.find(SELECTORS.JUNIOR_INPUT);
    this.$adultCountDisplay = this.$element.find(SELECTORS.ADULT_DISPLAY);
    this.$juniorCountDisplay = this.$element.find(SELECTORS.JUNIOR_DISPLAY);
    this.$kidCountDisplay = this.$element.find(SELECTORS.KID_DISPLAY);
    this.$minusButtons = this.$element.find(SELECTORS.MINUS_BUTTON);
    this.$plusButtons = this.$element.find(SELECTORS.PLUS_BUTTON);
    this.$closeButton = this.$element.find(SELECTORS.CLOSE_BUTTON);
    this.$closeIcon = this.$closeButton.find(SELECTORS.CLOSE_ICON);

    this.$firstFocus = this.$minusButtons.first();
    this.$lastButton = this.$plusButtons.last();

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {GuestNumberView}
   * @chainable
   * @public
   */
  enable() {
    $(document).on(EVENTS.CLICK, e => this.handleBodyClick(e));
    this.$displayInput.on(`${EVENTS.CLICK} ${EVENTS.KEYDOWN}`, e => this.handleShowPicker(e));
    this.$minusButtons.each((i, el) => $(el).on(EVENTS.CLICK, e => this.handleMinusClick(e)));
    this.$plusButtons.each((i, el) => $(el).on(EVENTS.CLICK, e => this.handlePlusClick(e)));
    this.$closeButton.on(EVENTS.CLICK, e => this.handleShowPicker(e));
    this.$lastButton.on(EVENTS.BLUR, () => this.handleLastButtonBlur());

    return this;
  }

  /**
   * Closes widget when user clicks outside
   *
   * @method handleBodyClick
   * @public
   */
  handleBodyClick(e) {
    const parent = $(e.target).closest(this.closestParent)[0];

    if (!parent) this.handleHidePicker();
  }

  /**
   * Captures click event on "minus" buttons
   * - Updates display countType
   * - Updates hidden field
   * - Updates totatl display field
   *
   * @method handleMinusClick
   * @public
   */
  handleMinusClick(e) {
    e.preventDefault();
    const type = $(e.currentTarget).data('countType');

    if (this[`${type}Count`] <= 0) return;

    this[`${type}Count`]--;

    this.updateCountItems(type);
  }

  /**
   * Captures click event on "plus" buttons
   * - Updates display countType
   * - Updates hidden field
   * - Updates totatl display field
   *
   * @method handlePlusClick
   * @public
   */
  handlePlusClick(e) {
    e.preventDefault();
    const type = $(e.currentTarget).data('countType');

    this[`${type}Count`]++;

    this.updateCountItems(type);
  }

  /**
   * Called after a minus/plus event
   * - Updates display countType
   * - Updates hidden field
   * - Updates totatl display field
   *
   * @method updateCountItems
   * @public
   */
  updateCountItems(type) {
    this.updateHiddenInput(type);
    this.updateTypeDisplay(type);
    this.updateTotalDisplay();
  }

  /**
   * Updates the hidden input for adult/kids count
   *
   * @method updateHiddenInput
   * @public
   */
  updateHiddenInput(inputType) {
    this[`$${inputType}Input`].val(this[`${inputType}Count`]);
  }

  /**
   * Updates the display field for adult/kids count
   *
   * @method updateTypeDisplay
   * @public
   */
  updateTypeDisplay(inputType) {
    this[`$${inputType}CountDisplay`].text(this[`${inputType}Count`]);
  }

  /**
   * Updates display field for total guests
   *
   * @method updateTotalDisplay
   * @public
   */
  updateTotalDisplay() {
    const total = this.adultCount + this.juniorCount + this.kidCount;
    const text = total === 1 ? this.singularText : this.pluralText;

    this.$displayInput.text(`${total} ${text}`).addClass(CLASSES.DISPLAY_ACTIVE);
    this.$displayInput.toggleClass('forceRender');
  }

  handleLastButtonBlur() {
    this.$closeButton.focus();
  }

  /**
   * Called when display input recieves focus
   *
   * @method handleShowPicker
   * @public
   */
  handleShowPicker(e) {
    this.$numberPickerFlyout.handleToggle(e);
  }

  handleHidePicker() {
    this.$numberPickerFlyout.hide();
  }

  afterShow() {
    this.$closeIcon.removeClass(CLASSES.ICON_DOWN).addClass(CLASSES.ICON_UP);
    this.$firstFocus.focus();
  }

  afterHide() {
    this.$closeIcon.removeClass(CLASSES.ICON_UP).addClass(CLASSES.ICON_DOWN);
  }
}
