/*
 * @fileOverview CustomVimeoPlayer
 */

 /**
  * A reference to the selectors used in this view
  *
  * @property SELECTORS
  * @type {Object}
  * @private
  */
 const DATA = {
   'VIDEO_ID': 'video-id'
 };

/**
 * Deal with lack of IE 10 support for vimeo player
 *
 * @class CustomVimeoPlayer
 */
export default class CustomVimeoPlayer  {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    this.$element = $element;
    this.createVideo();
  }

  /**
   * [createVideo will create an iframe underneath whatever element the view is set on. Expect data-video-id to be set]
   */
  createVideo() {
    const id = this.$element.data(DATA.VIDEO_ID);
    this.src = `https://player.vimeo.com/video/${id}`;
    this.$vid = $(`<iframe src="${this.src}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`);
    this.$element.append(this.$vid);
  }

  /**
   * [restart will reset the iframe url to force the video to stop / restart]
   */
  restart() {
    this.$vid.attr('src', '');
    this.$vid.attr('src', this.src);
  }
}
