import gsap from 'gsap';

console.log(gsap, 'gsap');

let myGsap = gsap || {};

const Power1 = myGsap.Power1 || window.Power1;
const Expo = myGsap.Expo || window.Expo;
const TweenMax = myGsap.TweenMax || window.TweenMax;

console.log(myGsap, Power1, Expo, TweenMax, gsap.Power1);

export {
  Power1 as Power1,
  TweenMax as TweenMax,
  Expo as Expo
};
