/*
 * @fileOverview ActivityCarouselView module definition
 */

import CarouselView from 'views/CarouselView';
import Utils from 'utils/Utils';
import { TweenMax, Power1 } from 'views/GsapLoader';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'BUTTON_PROMINENT': '.js-carousel-prominent',
  'PAGINATION_CONTAINER': '.js-carousel-pagination',
  'BG_LIST': '.js-bg-list',
  'CARD_COUNT': '.js-card-count',
  'SLIDE_TITLE': '.js-slide-title',
  'CONTROL_TEXT': '.js-carousel-control-text',
  'ACCORDION_TRIGGER': '.js-accordion-trigger',
  'PHOTO_BTN': '.js-photo-btn',
  'MAP_BTN': '.js-map-btn',
  'SLIDE_BTN': '.js-carousel-slide',
  'NAVIGATION_CONTAINER': '.js-carousel-navigation',
  'NAVIGATION_LEFT': '.js-carousel-nav-left',
  'NAVIGATION_RIGHT': '.js-carousel-nav-right',
  'CONTAINER': '.container'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'PAGINATION_ITEM_ACTIVE': 'carousel-pagination-item_active',
  'TOGGLE_BTN_ACTIVE': 'btn_toggleListActive',
  'SLIDE_BTN_ACTIVE': 'btn_squareActive',
  'NAVIGATION_SCROLL': 'carousel-navigation-list_scroll'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'RESIZE': 'resize'
};

/**
 * A reference to the animation settings used in this view
 *
 * @property ANIMATION
 * @type {Object}
 * @private
 */
const ANIMATION = {
  'DURATION': 0.4,
  'DURATION_FAST': 0.2,
  'EASE_OUT': Power1.easeOut
};

/**
 * Container object for storing times
 *
 * @constant TIMES
 * @type {Object}
 */
const TIMES = {
  'THROTTLE': 25
};

/**
 * Extends the base CarouselView and adds the methods that make the
 * Activity Carousel _its own thang_
 *
 * @class ActivityCarouselView
 */
export default class ActivityCarouselView extends CarouselView {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {ActivityCarouselView}
   * @public
   * @chainable
   */
  createChildren() {
    super.createChildren();

    this.$window = $(window);
    this.$body = $('html, body');
    this.$btnProminent = this.$element.find(SELECTORS.BUTTON_PROMINENT);
    this.$paginationContainer = this.$element.find(SELECTORS.PAGINATION_CONTAINER);
    this.$bgList = this.$element.find(SELECTORS.BG_LIST);
    this.$cardCount = this.$element.find(SELECTORS.CARD_COUNT);
    this.$accordionTrigger = this.$element.find(SELECTORS.ACCORDION_TRIGGER);
    this.$photoButton = this.$element.find(SELECTORS.PHOTO_BTN);
    this.$mapButton = this.$element.find(SELECTORS.MAP_BTN);
    this.$slideButton = this.$element.find(SELECTORS.SLIDE_BTN);
    this.$navigation = this.$element.find(SELECTORS.NAVIGATION_CONTAINER);
    this.$navigationLeft = this.$element.find(SELECTORS.NAVIGATION_LEFT);
    this.$navigationRight = this.$element.find(SELECTORS.NAVIGATION_RIGHT);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ActivityCarouselView}
   * @chainable
   * @public
   */
  enable() {
    this.$rightButton.on(EVENTS.CLICK, e => this.handleNextClick(e));
    this.$leftButton.on(EVENTS.CLICK, e => this.handlePrevClick(e));
    this.$btnProminent.on(EVENTS.CLICK, e => this.handleNextClick(e));
    Utils.swipeDetect(this.$element.get(0), swipeDir => this.handleTouchMove(swipeDir));
    this.$accordionTrigger.on(EVENTS.CLICK, e => this.handleAccordionClick(e));
    this.$photoButton.on(EVENTS.CLICK, e => this.handlePhotoClick(e));
    this.$mapButton.on(EVENTS.CLICK, e => this.handleMapClick(e));
    this.$slideButton.on(EVENTS.CLICK, e => this.handleSlideClick(e));
    this.$navigationLeft.on(EVENTS.CLICK, e => this.handleNavScrollLeftClick(e));
    this.$navigationRight.on(EVENTS.CLICK, e => this.handleNavScrollRightClick(e));
    this.$window.on(EVENTS.RESIZE, Utils.throttle(e => this.handleWindowResize(e), TIMES.THROTTLE));

    return this;
  }

  /**
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {ActivityCarouselView}
   * @chainable
   * @public
   */
  afterInit() {
    const domImageCount = this.$bgList.children().length;

    this.imageSlideCount = domImageCount+2;
    this.contentSlideCount = this.$items.length;
    this.currentImageSlide = 1;
    this.currentContentSlide = 0;
    this.isAnimating = false;
    this.isSwipeable = true;
    this.hasActivityCards = this.contentSlideCount > 0;
    this.hasNavigation = false;
    this.navBtnWidth = 57;
    this.navWidth = 0;
    this.isNavAnimating = false;
    this.windowWidth = this.$window.width();

    if (domImageCount === 1) {
      this.setSingleLayout();
    } else {
      this.setClones();
      this.setLayout();
    }

    if (this.$navigation.length > 0) {
      this.setNavigationScroll();
      this.setContentTabIndex(0);
    }
  }

  /**
   * After initialization, create clones of the first and last items
   * in order to set up an infinite scrolling slide effect
   *
   * @method setClones
   * @public
   */
  setClones() {
    const firstItemClone = this.$bgList.children().first().clone();
    const lastItemClone = this.$bgList.children().last().clone();

    this.$bgList
      .prepend(lastItemClone)
      .append(firstItemClone);
  }

  /**
   * After initialization, take rendered HTML information and set up the
   * constraints of the carousel layout
   *
   * @method setLayout
   */
  setLayout() {
    this.$firstBgItem = this.$bgList.children().first();
    this.$lastBgItem = this.$bgList.children().last();

    if (Utils.isDesktopViewport()) {
      this.$btnProminent.show();
    }
    else {
      this.$btnProminent.hide();
    }

    this.carouselWidth = this.$element.parents(CLASSES.CONTAINER).width();
    //hide rounding issue
    this.$element.css('width', `${this.carouselWidth - 1}px`);

    this.$bgList.css('width', `${this.carouselWidth * this.imageSlideCount}px`);
    this.$bgList.children().css('width', `${this.carouselWidth}px`);
    TweenMax.to(this.$bgList, 0, { 'x': `-${this.getActuatorPositionByItem(this.currentImageSlide)}px`});

    if (Utils.isDesktopViewport()) {
      // Reset tabindex accordion sets by default
      this.$items.first().find('a').attr('tabIndex', '');
    }
  }

  setSingleLayout() {
    this.$bgList.css('width', '100%');
    this.$bgList.children().css('width', '100%');
    this.$paginationContainer.hide();
    this.$btnProminent.hide();
    this.$rightButton.hide();
    this.$leftButton.hide();
    this.isSwipeable = false;
    TweenMax.to(this.$bgList, 0, { 'x': `0` });
  }

  /**
   * Handle click of an accordion trigger to get rid of layout glitch on slides
   *
   * @method handleAccordionClick
   * @param {Event}
   */
  handleAccordionClick(event) {
    const $el = $(event.currentTarget);
    if ($el.hasClass('js-accordion-pane_active')) {
      this.resetSlideHeight();
    }
  }

  /**
   * Handle a touchmove event on the carousel.
   * Return early if animating
   *
   * @method handleTouchMove
   * @param {String} swipeDir [The returned swipe direction of the user's touch]
   */
  handleTouchMove(swipeDir) {
    if (this.isAnimating || !this.isSwipeable) return;

    if (swipeDir === 'right') {
      this.transitionSlides(false);
    } else if (swipeDir === 'left') {
      this.transitionSlides(true);
    }
  }

  /**
   * Handle the click of a button associated with moving to the next slide.
   *
   * @method handleNextClick
   * @param {Event} event [Javascript Event Object]
   */
  handleNextClick(event) {
    if (this.isAnimating) return;

    this.transitionSlides(true);
  }

  /**
   * Handle the click of a button associated with moving to the previous slide.
   *
   * @method handlePrevClick
   * @param {Event} event [Javascript Event Object]
   */
  handlePrevClick(event) {
    if (this.isAnimating) return;

    this.transitionSlides(false);
  }

  /**
   * Handle the click of a slide's photo button.
   *
   * @method handlePhotoClick
   * @param {Event} event [Javascript Event Object]
   */
  handlePhotoClick(event) {
    const $el = $(event.currentTarget);
    const $listItem = this.$bgList.children().eq(this.currentImageSlide);
    const background = `url('${$listItem.data('photo')}')`;

    $el.addClass(CLASSES.TOGGLE_BTN_ACTIVE);
    $el.parent().next().find(this.$mapButton).removeClass(CLASSES.TOGGLE_BTN_ACTIVE);
    $listItem.css('background-image', background);
  }

  /**
   * Handle the click of a slide's map button.
   *
   * @method handleMapClick
   * @param {Event} event [Javascript Event Object]
   */
  handleMapClick(event) {
    const $el = $(event.currentTarget);
    const $listItem = this.$bgList.children().eq(this.currentImageSlide);
    const background = `url('${$listItem.data('map')}')`;

    $el.addClass(CLASSES.TOGGLE_BTN_ACTIVE);
    $el.parent().prev().find(this.$photoButton).removeClass(CLASSES.TOGGLE_BTN_ACTIVE);
    $listItem.css('background-image', background);
  }

  /**
   * Handle the click of a slide number button.
   *
   * @method handleSlideClick
   * @param {Event} event [Javascript Event Object]
   */
  handleSlideClick(event) {
    const $el = $(event.currentTarget);
    const newItem = $el.text().trim();
    const $activeSlide = this.$items.eq(this.currentContentSlide);
    const $nextSlide = this.$items.eq(newItem - 1);

    if (this.isAnimating) return;

    this.isAnimating = true;
    this.$slideButton.removeClass(CLASSES.SLIDE_BTN_ACTIVE);
    $el.addClass(CLASSES.SLIDE_BTN_ACTIVE);

    this.actuateBackgrounds(newItem, () => {
      this.isAnimating = false;
      this.currentImageSlide = this.getUpdatedImageSlide(newItem);
    });

    if (this.hasActivityCards) {
      this.transitionContent($activeSlide, $nextSlide);
      this.setContentTabIndex(newItem - 1);
      this.currentContentSlide = newItem - 1;
    }
  }

  /**
   * Handle the click of the left navigation scroll button.
   *
   * @method handleNavScrollLeftClick
   * @param {Event} event [Javascript Event Object]
   */
  handleNavScrollLeftClick(event) {
    const position = this.$navigation.position();
    const xPosition = position.left;
    const distance = -xPosition < this.navBtnWidth ? 0 : xPosition + this.navBtnWidth;

    if (distance > 0 || this.isNavAnimating) return;

    this.isNavAnimating = true;

    TweenMax.to(this.$navigation, ANIMATION.DURATION_FAST, {
      'x': `${distance}px`,
      'ease': ANIMATION.EASE_OUT,
      'onComplete': () => {
        this.isNavAnimating = false;
      }
    });
  }

  /**
   * Handle the click of the right navigation scroll button.
   *
   * @method handleNavScrollRightClick
   * @param {Event} event [Javascript Event Object]
   */
  handleNavScrollRightClick(event) {
    const position = this.$navigation.position();
    const xPosition = position.left;
    const bodyWidth = this.$body.outerWidth();
    const offestTotal = -(this.navWidth - bodyWidth + (this.navBtnWidth * 3));
    const distanceDiff = xPosition - this.navBtnWidth - offestTotal;
    const distance = distanceDiff < this.navBtnWidth ? xPosition - distanceDiff + 3 : xPosition - this.navBtnWidth;

    if (distance < offestTotal || this.isNavAnimating) return;

    this.isNavAnimating = true;

    TweenMax.to(this.$navigation, ANIMATION.DURATION_FAST, {
      'x': `${distance}px`,
      'ease': ANIMATION.EASE_OUT,
      'onComplete': () => {
        this.isNavAnimating = false;
      }
    });
  }

  /**
   * Calls methods associated with a window resize
   *
   * @method handleWindowResize
   * @param {Event} event JavaScript event object
   * @public
   */
  handleWindowResize(event) {
    const resizedWindowWidth = this.$window.width();

    if (this.windowWidth === resizedWindowWidth) {
      return;
    }

    if (domImageCount !== 1) {
      this.setLayout();
    }

    if (this.$navigation.length > 0) {
      this.navWidth = 0;
      this.$navigation.css({
        'width': 'auto',
        'transform': ''
      });
      this.setNavigationScroll();
    }

    this.windowWidth = resizedWindowWidth;
  }

  /**
   * Kick off methods needed to transition all slides of this carousel
   *
   * @method transitionSlides
   * @param {Bool} moveForward [whether the carousel should move forward]
   */
  transitionSlides(moveForward) {
    const newItem = this.getNewItem(moveForward);

    this.isAnimating = true;
    this.actuateBackgrounds(newItem, () => {
      this.isAnimating = false;
      this.currentImageSlide = this.getUpdatedImageSlide(newItem);
      this.determineActuatorReset(newItem);
      this.updatePagination(this.currentImageSlide-1);
    });

    if (this.hasActivityCards) {
      this.swapContentSlides(moveForward);
    }
  }

  /**
   * Call methods for content swap
   *
   * @method swapContentSlides
   * @param {Bool} moveForward [whether the carousel should move forward]
   */
  swapContentSlides(moveForward) {
    const $activeSlide = this.$items.eq(this.currentContentSlide);
    const nextContentIndex = this.getNextContentSlide(moveForward);
    const $nextSlide = this.$items.eq(nextContentIndex);

    this.transitionContent($activeSlide, $nextSlide);
    this.updateMetaInfo(nextContentIndex);
    this.currentContentSlide = nextContentIndex;
  }

  /**
   * Fade in a new content slide and hide the last one.
   *
   * @method transitionContent
   * @param {jQuery} $activeSlide
   * @param {jQuery} $$nextSlide
   */
  transitionContent($activeSlide, $nextSlide) {
    TweenMax.to($nextSlide, ANIMATION.DURATION*2, {
      'onStart': () => {
        $activeSlide.css({
          'height': 0,
          'min-height': 0,
          'opacity': 0
        });
        $activeSlide.find('a').attr('tabIndex', -1);
        $nextSlide.css({
          'height': 'auto',
          'min-height': $nextSlide.get(0).scrollHeight
        });
        $nextSlide.find('a').attr('tabIndex', '');
      },
      'opacity': 1
    });
  }

  /**
   * Reset the height of a slide so it can collapse
   *
   * @method resetSlideHeight
   */
  resetSlideHeight() {
    this.$items.eq(this.currentContentSlide).css({
      'height': 'auto',
      'min-height': 0
    });
  }

  /**
   * Update any text or indicators on the carousel as it moves
   *
   * @method updateMetaInfo
   * @param {Number} newIndex [The index of the new slide being shown]
   */
  updateMetaInfo(newIndex) {
    const nextContentIndex = newIndex + 1 === this.contentSlideCount ? 0 : newIndex + 1;
    const nextHtml = this.$items.eq(nextContentIndex).find(SELECTORS.SLIDE_TITLE).text();

    this.$cardCount.text(newIndex + 1);
    this.$btnProminent
      .find(SELECTORS.CONTROL_TEXT)
      .text(nextHtml);
  }

  updatePagination(newIndex) {
    const $paginationCircles = this.$paginationContainer.children();

    $paginationCircles
      .removeClass(CLASSES.PAGINATION_ITEM_ACTIVE)
      .eq(newIndex).addClass(CLASSES.PAGINATION_ITEM_ACTIVE);
  }

  /**
   * Animated the actuator container of the background images
   *
   * @method actuateBackgrounds
   * @param {Number} newItem [The position of the new background item to show]
   * @param {function} onComplete [callback to execute on animation complete]
   */
  actuateBackgrounds(newItem, onComplete) {
    const actuatorPosition = this.getActuatorPositionByItem(newItem);

    TweenMax.to(this.$bgList, ANIMATION.DURATION, {
      'x': `-${actuatorPosition}px`,
      'ease': ANIMATION.EASE_OUT,
      onComplete
    });
  }

  /**
   * Set bg image list actuator to an item if the current item is a clone.
   * We do this to get the infinite affect but then set to the correct item
   * behind the scenes
   *
   * @method determineActuatorReset
   * @param {Number} newItem [The position of the new background item to show]
   */
  determineActuatorReset(newItem) {
    if (newItem === this.imageSlideCount - 1) {
      this.resetActuatorToItem(this.currentImageSlide);
    } else if (newItem === 0) {
      this.resetActuatorToItem(this.currentImageSlide);
    }
  }

  /**
   * Set bg image list actuator to an item if the current item is a clone.
   * We do this to get the infinite affect but then set to the correct item
   * behind the scenes
   *
   * @method getUpdatedImageSlide
   * @param {Number} newItem [The position of the new background item to show]
   */
  getUpdatedImageSlide(newItem) {
    if (newItem === this.imageSlideCount - 1) {
      return 1;
    } else if (newItem === 0) {
      return this.imageSlideCount - 2;
    }
    return newItem;
  }

  /**
   * Hard set actuator to actual item instead of its clone
   *
   * @method resetActuatorToItem
   * @param {Number} item [The image slide position to go to]
   */
  resetActuatorToItem(item) {
    TweenMax.to(this.$bgList, 0, { 'x': `-${this.getActuatorPositionByItem(item)}px`});
  }

  /**
   * Find the next content slide position based on whether or not the
   * carousel is moving forward and what the current slide is
   *
   * @method getNextContentSlide
   * @param {Bool} moveForward [whether the carousel should move forward]
   * @return {Number}
   */
  getNextContentSlide(moveForward) {
    if (moveForward && this.currentContentSlide === this.contentSlideCount - 1) {
      return 0;
    } else if (!moveForward && this.currentContentSlide === 0) {
      return this.contentSlideCount - 1;
    } else {
      return moveForward ? this.currentContentSlide + 1 : this.currentContentSlide - 1;
    }
  }

  /**
   * Find the next bg image item based on whether or not the carousel is
   * moving forward and what the current slide is
   *
   * @method getNewItem
   * @param {Bool} moveForward [whether the carousel should move forward]
   * @return {Number}
   */
  getNewItem(moveForward) {
    return moveForward ? this.currentImageSlide + 1 : this.currentImageSlide - 1;
  }

  /**
   * Get the Actuator percent to set based on the item it needs to show
   *
   * @method getActuatorPositionByItem
   * @param {Number} item [The item to show in the carousel]
   * @return {Number}
   */
  getActuatorPositionByItem(item) {
    return item * this.carouselWidth;
  }

  /**
   * Set scrolling on navigation if viewport is less than nav width
   *
   * @method setNavigationScroll
   */
  setNavigationScroll() {
    const bodyWidth = this.$body.outerWidth();

    this.$navigation.children().each((index, el) => {
      this.navWidth = this.navWidth + $(el).outerWidth();
    });

    if (this.navWidth > bodyWidth) {
      this.$navigation.css('width', this.navWidth);
      this.$navigation.addClass(CLASSES.NAVIGATION_SCROLL);
      this.$navigationLeft.show();
      this.$navigationRight.show();
    } else {
      this.$navigation.removeClass(CLASSES.NAVIGATION_SCROLL);
      this.$navigationLeft.hide();
      this.$navigationRight.hide();
    }
  }

  /**
   * Set tabIndex on slide content so hidden ones are disabled
   *
   * @method setContentTabIndex
   * @param {Number} contentItem [Content item to enable tabIndex on]
   */
  setContentTabIndex(contentItem) {
    this.$items.find('button').attr('tabIndex', -1);
    this.$items.eq(contentItem).find('button').attr('tabIndex', '');
  }
}
