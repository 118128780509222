/*
 * @fileOverview FormView module definition
 */

import FormView from 'views/FormView';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'FIELD': '.js-field',
  'EMAIL': '.js-email',
  'MESSAGE': '.js-error-message'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'COLOR_ERROR': 'mix-text_colorError',
  'COLOR_LIGHT': 'mix-text_color1',
  'FIELD_MESSAGE': 'field_message'
}

/**
 * A reference to the data attributes used in this view
 *
 * @property DATA
 * @type {Object}
 * @private
 */
const DATA = {
  'LISTID': 'list-id'
};

/**
 * Submits newsletter to correct service
 *
 * @class NewsletterFormView
 * @constructor
 */
export default class NewsletterFormView extends FormView {

  /*
  * Provides a post initialization hook for additional setup
  * outside of event and child setup
  *
  * @property afterInit
  * @returns {FormView}
  * @public
  */
 afterInit() {
   this.$email = this.$element.find(SELECTORS.EMAIL);
 }

  /**
   * Handle form submit
   *
   * @method handleFormSubmit
   * @param {Event} JavaScript event object
   * @public
   */
  handleFormSubmit(event) {
    event.preventDefault();

    if (this.validateForm()) {
      this.submitNewsletter();
    }
  }

  /**
   * Submit data to Silverpop
   *
   * @method submitForm
   * @public
   */
  submitNewsletter() {
    const $field = this.$email.closest(SELECTORS.FIELD);
    const settings = {
      'data': {
        'email': this.$email.val(),
        'listId' : this.$element.data(DATA.LISTID)
       },
      'error': (error) => {
        this.showErrorMessage($field, 'There was an error. Please try again.');
      },
      'success': (data) => {
        this.showMessage($field,'Your email has been added.');
      },
      'type': 'POST',
      'url': 'Forms/Newsletter'
    }

    $.ajax(settings);
  }

  /**
   * Shows a non-error message under the email field
   *
   * @method showMessage
   * @param {Object} $field jQuery field selector
   * @param {String} message Message to be shown under the field
   */
  showMessage($field, message) {
    $field.find(SELECTORS.MESSAGE)
      .removeClass(CLASSES.COLOR_ERROR)
      .addClass(`${CLASSES.COLOR_LIGHT} ${CLASSES.FIELD_MESSAGE}`)
      .text(message)
      .parent().show();
  }
}
