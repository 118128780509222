/*
 * @fileOverview PopoutView module definition
 */

import View from 'views/View';
import ModalView from 'views/ModalView';
import { Power1 } from 'views/GsapLoader';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'CLOSE': '.js-toaster-close',
  'MSG': '.js-modal-msg', //level 2 with text
  'MODAL': '.js-popout-modal',
  'MSG_BUTTON': '.js-popout-item' //level 1
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
    'HIDE_TOASTER': 'mix-band_hidden'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

/**
 * A reference to the animation settings used in this view
 *
 * @property ANIMATION
 * @type {Object}
 * @private
 */
const ANIMATION = {
    'TIME': 0.5,
    'EASE': Power1.easeOut
};


/**
 * Open and close toaster
 *
 * @class PopoutView
 */
export default class PopoutView extends View {
    /**
     * Create any child objects or references to DOM elements.
     *
     * @method createChildren
     * @returns {PopoutView}
     * @public
     * @chainable
     */
    createChildren() {
        this.$closeBtn = this.$element.find(SELECTORS.CLOSE);
        this.$modal = this.$element.find(SELECTORS.MODAL);
        this.$modalView = new ModalView(this.$modal, {
            'afterHide': this.afterModalHide.bind(this)
        });
        this.$msgButton = this.$element.find(SELECTORS.MSG_BUTTON);

        return this;
    }

    /**
     * Enable event handlers.
     * Exits early if component already enabled.
     *
     * @method enable
     * @returns {PopoutView}
     * @chainable
     * @public
     */
    enable() {
        this.$msgButton.on(EVENTS.CLICK, e => this.handleMessageClick(e));
        this.showToaster();

        return this;
    }

    /**
     * Handles click event of user clicking message bar
     *
     * @method handleMessageClick
     * @public
     */
    handleMessageClick() {
        this.$modalView.show();
    }

    afterModalHide() {
        // Do nothing.
    }

    /**
     * Show toaster element if cookie not set
     *
     * @method showToaster
     * @returns {PopoutView}
     * @public
     */
    showToaster() {
        // Do nothing.
    }

    /**
     * Close toaster element
     *
     * @method closeToaster
     * @returns {PopoutView}
     * @public
     */
    closeToaster() {
        // Do nothing.
    }
}
