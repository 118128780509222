/*
 * @fileOverview CarouselView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';
import { TweenMax, Expo } from 'views/GsapLoader';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'LIST': '.js-carousel-list',
  'ITEMS': '.js-carousel-list-item',
  'BUTTON_LEFT': '.js-carousel-left',
  'BUTTON_RIGHT': '.js-carousel-right',
  'CAROUSEL': '.js-carousel'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'BUTTON_ACTIVE': 'carousel-btn_active'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

/**
 * A reference to the animation settings used in this view
 *
 * @property ANIMATION
 * @type {Object}
 * @private
 */
const ANIMATION = {
  'DURATION': 0.5,
  'EASE_IN': Expo.easeIn
};

/**
 * Scrolls through a list of items presenting a number at a time
 * Set data-carousel-window-size to determine number of items shown at a time.
 * This defaults to a scrubbable list on mobile
 *
 * @class CarouselView
 */
export default class CarouselView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {CarouselView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$list = this.$element.find(SELECTORS.LIST);
    this.$items = this.$element.find(SELECTORS.ITEMS);
    this.$leftButton = this.$element.find(SELECTORS.BUTTON_LEFT);
    this.$rightButton = this.$element.find(SELECTORS.BUTTON_RIGHT);

    if (this.$element.hasClass(SELECTORS.CAROUSEL)) {
      this.$carousel = this.$element;
    }
    else {
      this.$carousel = this.$element.find(SELECTORS.CAROUSEL);
    }

    return this;
  }

  /**
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit() {
    this.itemsTotal = this.$items.length;

    // itemWindow determines how many items to show at a time.
    // data-carousel-window-size="3" will show 3 items at a time
    this.itemWindow = this.$carousel.data('carouselWindowSize') || 1;

    this.currentItem = 0;
    this.currentPosition = 0;

    this.setListWidth();
    this.toggleArrows();
  }

  /**
   * Sets the width of the list of items
   * Larger for desktop, smaller for mobile
   *
   * @property setListWidth
   * @public
   */
  setListWidth() {
    this.itemWidth = this.$items.first().outerWidth();
    const totalItems = Utils.isLargeDesktopViewport() ? this.itemsTotal + 1 : this.itemsTotal;

    this.$list.css('width', this.itemWidth * this.itemsTotal);
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {CarouselView}
   * @chainable
   * @public
   */
  enable() {
    this.$rightButton.on(EVENTS.CLICK, e => this.scrollRight(e));
    this.$leftButton.on(EVENTS.CLICK, e => this.scrollLeft(e));

    return this;
  }

  /**
   * Determines if user can scroll right
   *
   * @property canScrollRight
   * @public
   */
  canScrollRight() {
    return ((this.currentItem + 1) * this.itemWindow) < this.itemsTotal;
  }

  /**
   * Determines if user can scroll left
   *
   * @property canScrollLeft
   * @public
   */
  canScrollLeft() {
    return this.currentItem > 0;
  }

  /**
   * Scrolls to the next item or items in the list
   *
   * @property scrollRight
   * @public
   */
  scrollRight(e) {
    if (!this.canScrollRight()) return;
    this.currentItem++;

    this.doScroll(-this.itemWidth);
    this.focusNextButton();
  }

  /**
   * Scrolls to the previous item or items in the list
   *
   * @property scrollLeft
   * @public
   */
  scrollLeft(e) {
    if (!this.canScrollLeft()) return;
    this.currentItem--;

    this.doScroll(this.itemWidth);
    this.focusPreviousButton();
  }

  /**
   * Actually does the scrolling to next/previous
   * items in the list with animation
   *
   * @property doScroll
   * @public
   */
  doScroll(amount) {
    this.currentPosition += amount;

    TweenMax.to(this.$list, ANIMATION.DURATION, {
      'transform': `translateX(${this.currentPosition}px)`,
      'ease': ANIMATION.EASE_IN
    });

    this.toggleArrows();
  }

  /**
   * Determines whether the prev/next arrows should
   * be visible and hides/shows them
   *
   * @property toggleArrows
   * @public
   */
  toggleArrows() {
    if (this.canScrollLeft()) {
      this.showArrow(this.$leftButton);
    }
    else {
      this.hideArrow(this.$leftButton);
    }

    if (this.canScrollRight()) {
      this.showArrow(this.$rightButton);
    }
    else {
      this.hideArrow(this.$rightButton);
    }
  }

  /**
   * Shows an arrow passed to it
   *
   * @property showArrow
   * @public
   */
  showArrow($arrow) {
    $arrow.addClass(CLASSES.BUTTON_ACTIVE);
  }

  /**
   * Hides an arrow passed to it
   *
   * @property hideArrow
   * @public
   */
  hideArrow($arrow) {
    $arrow.removeClass(CLASSES.BUTTON_ACTIVE);
  }

  /**
   * [focusNextButton focus on the next button]
   * @return {boolean} [was focus successful]
   */
  focusNextButton() {
    if (this.canScrollRight()) {
      this.$rightButton.focus();
      return true;
    }
    else {
      return false;
    }
  }

  /**
   * [focusPreviousButton focus the previous button]
   * @return {boolean} [was focus successful]
   */
  focusPreviousButton() {
    if (this.canScrollLeft()) {
      this.$leftButton.focus();
      return true;
    }
    else {
      return false;
    }
  }
}
