
/*
 * @fileOverview DatepickerView module definition
 */

import DatepickerView from 'views/DatepickerView';
import moment from 'moment';

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'CELL_ACTIVE': 'datepicker-cell_active',
  'CELL_HIGHLIGHT': 'datepicker-cell_highlight',
  'CELL_CAP_START': 'datepicker-cell_capStart',
  'CELL_CAP_END': 'datepicker-cell_capEnd',
  'DISPLAY_ACTIVE': 'btn_displayInputActive'
};

/**
 * Toggles subNav height and aria roles in an DatepickerView view component
 *
  * @class DatepickerView
 */
export default class DualDatepickerView extends DatepickerView {
  /*
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit () {
    this.closestParent = '[data-module="dual-datepicker"]';

    super.afterInit();

    return this;
  }
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {DatepickerView}
   * @public
   * @chainable
   */
  createChildren() {
    this.minimumMonthsShown = 2;

    super.createChildren();

    return this;
  }

  /**
   * Callback for jQueryDatepicker's beforeShowDay hook
   *
   * Applies css class to selected dates and
   * dates within the selected range highlighting
   * them visibly for the user
   *
   * @method highlightCells
   * @returns {[shouldDisplayCell, cssClass]}
   * @public
   */
  highlightCells(date) {
    const cellDate = new Date(date);
    const momentDate = moment(date);
    const isStartDate = momentDate.isSame(this.startDate);
    const isEndDate = momentDate.isSame(this.endDate)
    let cssClass = '';

    if (isStartDate || isEndDate) {
      cssClass = CLASSES.CELL_ACTIVE;

      if (this.startDate && this.endDate) {
        if (isStartDate) {
          cssClass += ` ${CLASSES.CELL_CAP_START}`;
        }
        else if (isEndDate) {
          cssClass += ` ${CLASSES.CELL_CAP_END}`;
        }
      }
    }
    else if (momentDate.isAfter(this.startDate) &&momentDate.isBefore(this.endDate)) {
      cssClass = CLASSES.CELL_HIGHLIGHT;
    }

    return [true, cssClass];
  }

  /**
   * Sets internal variables for tracking start & end dates
   * Will reset variables if both are already selected
   * Will enforce valid selection
   *
   * @method setInternalDates
   * @public
   */
  setInternalDates(date) {
    const selectedDate = new Date(date);
    const momentDate = moment(selectedDate);
    const settingFirstDates = !this.startDate || !this.endDate;
    const changeExistingDates = this.startDate && this.endDate;
    const isBeforeStartDate =momentDate.isBefore(this.startDate);
    const isBeforeEndDate = momentDate.isBefore(this.endDate);

    if (changeExistingDates) {
      if (!isBeforeStartDate && isBeforeEndDate) {
        this.startDate = momentDate;
        this.endDate = undefined;
      }
      else if (!isBeforeEndDate) {
        this.endDate = momentDate;
      }
      else if (isBeforeStartDate) {
        this.startDate = momentDate;
      }
    }
    else if (settingFirstDates) {
      if (!this.startDate || isBeforeStartDate) {
        this.startDate = momentDate;
        this.endDate = undefined;
      }
      else {
        this.endDate = momentDate;
      }
    }
  }

  /**
   * Updates display input
   *
   * @method updateDisplay
   * @public
   */
  updateDisplay() {
    const start = this.startDate ? moment(this.startDate).format('MMM D') : 'Check In';
    const end = this.endDate ? moment(this.endDate).format('MMM D') : 'Check Out';

    this.$displayInput.text(`${start} - ${end}`).addClass(CLASSES.DISPLAY_ACTIVE);
  }
}
