/*
 * @fileOverview SearchView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'INPUT': '.js-search-input',
  'MASK': '.js-search-modal',
  'LOGO': '.js-logo',
  'TRIGGER': '.js-search-trigger',
  'CLOSE': '.js-search-close',
  'CONTAINER': '.js-search-container',
  'CONDITIONS': '.js-navConditions'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'FOCUS_IN': 'focusin',
  'FOCUS_OUT': 'focusout',
  'SCROLL': 'scroll',
  'RESIZE': 'resize'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'NO_SCROLL': 'noScroll',
  'SEARCH_OPEN': 'search_open'
};

/**
 * Container object for storing times
 *
 * @constant TIMES
 * @type {Object}
 */
const TIMES = {
  'THROTTLE': 25
};

/**
 * Controls search form display states
 *
 * @class SearchView
 * @constructor
 */
export default class SearchView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {SearchView}
   * @public
   * @chainable
   */
  createChildren () {
    this.$body = $('html,body');
    this.$window = $(window);

    this.$container = this.$element.find(SELECTORS.CONTAINER);
    this.$trigger = this.$element.find(SELECTORS.TRIGGER);
    this.$close = this.$element.find(SELECTORS.CLOSE);
    this.$mask = this.$element.find(SELECTORS.MASK);

    this.$input = this.$body.find(SELECTORS.INPUT);
    this.$conditions = this.$body.find(SELECTORS.CONDITIONS);
    this.$logo = this.$body.find(SELECTORS.LOGO);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {SearchView}
   * @chainable
   * @public
   */
  enable () {
    this.$input.on(EVENTS.FOCUS_IN,  e => this.handleInputFocusIn(e));
    this.$input.on(EVENTS.FOCUS_OUT, e => this.handleInputFocusOut(e));
    this.$close.on(EVENTS.FOCUS_OUT, e => this.handleCloseFocusOut(e));
    this.$trigger.on(EVENTS.CLICK, e => this.handleTriggerClick(e));
    this.$close.on(EVENTS.CLICK, e => this.handleCloseClick(e));
    this.$mask.on(EVENTS.CLICK, e => this.handleMaskClick(e));
    this.$window.on(EVENTS.RESIZE, Utils.throttle(e => this.handleWindowResize(e), TIMES.THROTTLE));

    return this;
  }

  /**
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {FormView}
   * @public
   */
  afterInit() {
    this.isSearchOpen = false;
  }

  /**
   * Calls methods associated with a window resize
   *
   * @method handleWindowResize
   * @param {Event} event JavaScript event object
   * @public
   */
  handleWindowResize(event) {
    if (!Utils.isLargeDesktopViewport() || !this.isSearchOpen) return;

    this.setSearchContainerBounds();
  }

  /**
   * Closes search on a mask click
   *
   * @method handleMaskClick
   * @param {Event} event JavaScript event object
   * @public
   */
  handleMaskClick(event) {
    this.closeSearch();
  }

  /**
   * Calls methods associated with input focus out
   *
   * @method handleInputFocusOut
   * @param {Event} event JavaScript event object
   * @public
   */
  handleInputFocusOut(event) {
    if (Utils.isLargeDesktopViewport()) {
      this.$close.focus();
    } else {
      this.hideMask();
    }
  }

  /**
   * Focuses to input when the close button is focused out
   *
   * @method handleCloseFocusOut
   * @param {Event} event JavaScript event object
   * @public
   */
  handleCloseFocusOut(event) {
    this.$input.focus();
  }

  /**
   * Handle input focus
   *
   * @method handleInputFocus
   * @param {Event} JavaScript event object
   * @public
   */
  handleInputFocusIn(event) {
    if (Utils.isLargeDesktopViewport()) return;

    this.showMask();
  }

  /**
   * Calls open method on trigger click.
   *
   * @method handleTriggerClick
   * @param {Event} JavaScript event object
   * @public
   */
  handleTriggerClick(event) {
    this.openSearch();
  }

  /**
   * Calls close method on close click.
   *
   * @method handleCloseClick
   * @param {Event} JavaScript event object
   * @public
   */
  handleCloseClick(event) {
    this.closeSearch();
  }

  /**
   * Opens search form on desktop.
   *
   * @method openSearch
   * @public
   */
  openSearch() {
    if (this.isSearchOpen) return;
    this.isSearchOpen = true;

    this.$body.addClass(CLASSES.NO_SCROLL);

    if (Utils.isDesktopViewport()) {
      this.setSearchContainerBounds();
    }

    this.$container.addClass(CLASSES.SEARCH_OPEN);
    this.$input.focus();
    this.showMask();
  }

  /**
   * Closes search form on desktop.
   *
   * @method closeSearch
   * @public
   */
  closeSearch() {
    if (!this.isSearchOpen) return;
    this.isSearchOpen = false;

    this.$container.removeClass(CLASSES.SEARCH_OPEN)
      .attr('style', '');
    this.$trigger.focus();
    this.hideMask();
    this.$body.removeClass(CLASSES.NO_SCROLL);
    //Safari Bug Fixing
    let searchElement = document.querySelector(`header .primaryNavBar .nav-search`);
    let searchParent = searchElement.parentNode;
    searchParent.removeChild(searchElement);
    searchParent.appendChild(searchElement);
  }

  /**
   * Sets width equal to total minus logo
   *
   * @method setSearchContainerBounds
   * @public
   */
  setSearchContainerBounds() {
    const logoWidth = this.$logo.get(0).clientWidth;

    this.$container.css({
      'left': logoWidth,
      'width': this.$window.width() - logoWidth
    });
  }

  /**
   * Show mask
   *
   * @method showMask
   * @public
   */
  showMask() {
    const searchHeight = this.$element.outerHeight();
    const searchOffset = this.$element.offset();
    const searchOffsetTop = searchOffset.top;

    if (Utils.isLargeDesktopViewport()) {
      this.$mask.css('top', `${searchHeight + searchOffsetTop}px`);
    }

    this.$mask.show();
  }

  /**
   * Hide mask
   *
   * @method hideMask
   * @public
   */
  hideMask() {
    if (!Utils.isLargeDesktopViewport()) {
      setTimeout(() => {
        this.$mask.hide();
      }, 100);
    } else {
      this.$mask.hide();
    }
  }
}
