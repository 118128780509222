
/*
 * @fileOverview FilterView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

const DATA = {
  'CATEGORY': 'category',
  'ID': 'id',
  'BEHAVIOR': 'behavior',
  'TAGS': 'tags'
}

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'CARD': '.js-card',
  'CHECKBOX': '.js-checkbox',
  'TRIGGER': '.js-filter-btn',
  'CLEAR': '.js-clear-trigger',
  'REMOVE': '.js-remove-filter',
  'FILTEROPTIONS': '.js-filter-options',
  'NAV_ITEM': '.js-filter-nav-item',
  'FILTER_SELECTED': '.js-filter-selected',
  'FILTER_SELECTED_LIST': '.js-filter-selected-list',
  'FILTER_SELECTED_ITEM': '.js-filter-selected-item',
  'FILTER_REMOVE': '.js-remove-filter',
  'ICON': '.js-filter-icon',
  'ZERO_MESSAGE': '.js-filter-zero-message'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'FILTER_ACTIVE': 'filter-options_active',
  'NAV_ACTIVE': 'filterItem_active',
  'VISUALLY_HIDDEN': 'isVisuallyHidden',
  'SELECTED_FILTER_ACTIVE': 'filter-selected-item_active',
  'OPEN': 'mix-icon_rotate270',
  'CLOSED': 'mix-icon_rotate90'
};


/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'CHANGE': 'change',
  'NAVCLICK': 'navigation-trigger',
  'KEYDOWN': 'keydown',
  'SCROLL': 'scroll'
};

/**
 * Container object for storing times
 *
 * @constant TIMES
 * @type {Object}
 */
const TIMES = {
  'THROTTLE': 25
};

/**
 * Toggles subNav height and aria roles in an FilterView view component
 *
 * @class FilterView
 */
export default class FilterView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {FilterView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$body = $('html,body');
    this.$buttons = this.$element.find(SELECTORS.TRIGGER);
    this.$checkboxes = this.$element.find(SELECTORS.CHECKBOX);
    this.$clearFilters = this.$element.find(SELECTORS.CLEAR);
    this.$selectedPanel = this.$element.find(SELECTORS.FILTER_SELECTED);
    this.$filterSelectedItems = this.$element.find(SELECTORS.FILTER_SELECTED_ITEM);
    this.$removeFilter = this.$element.find(SELECTORS.FILTER_REMOVE);
    this.$filteroptions = this.$element.find(SELECTORS.FILTEROPTIONS);
    this.$tabs = this.$element.find(SELECTORS.NAV_ITEM);
    this.$cards = this.$element.find(SELECTORS.CARD);
    this.$zeroMessage = this.$element.find(SELECTORS.ZERO_MESSAGE);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {FilterView}
   * @chainable
   * @public
   */
  enable() {
    this.$body.on(EVENTS.CLICK, () => this.closeActiveFilter());
    this.$body.on(EVENTS.NAVCLICK, () => this.closeActiveFilter());
    this.$buttons.on(EVENTS.CLICK, e =>e.stopPropagation());
    this.$buttons.on(EVENTS.CLICK, e => this.handleButtonClick(e));
    this.$checkboxes.on(EVENTS.CLICK, e => e.stopPropagation());
    this.$checkboxes.on(EVENTS.CHANGE, e => this.handleCheckboxChange(e));
    this.$clearFilters.on(EVENTS.CLICK, () => this.clearFilters());
    this.$removeFilter.on(EVENTS.CLICK, e => this.handleRemoveFilterClick(e));
    const optionGroups = this.$filteroptions;
    optionGroups.each((idx, element) => {
      const $optionGroup = $(element);
      const $checkBoxes = $optionGroup.find(SELECTORS.CHECKBOX);
      $checkBoxes.last().on(EVENTS.KEYDOWN, e => this.handleCheckboxTabForward(e));
      $checkBoxes.first().on(EVENTS.KEYDOWN, e => this.handleCheckboxTabBack(e));
    });
    this.$tabs.on(EVENTS.KEYDOWN, e => this.handleFilterButtonTab(e));
    this.$tabs.on(EVENTS.SCROLL, Utils.throttle(this.handleScroll.bind(this), TIMES.THROTTLE))

    return this;
  }

  /**
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {FilterView}
   * @public
   */
  afterInit() {
    this.shownCards = [];
    this.filters = [];
    this.filterCount = 0;
    this.isFirstFilter = true;
    this.categoryBehaviors = [];

    this.bindResultsTabBack();
  }

  /**
   * [handleScroll find open option group and reposition if needed]
   * @return {[type]} [void]
   */
  handleScroll(event) {
    if (Utils.isDesktopViewport()) {
      const $active = this.$element.find(`.${CLASSES.FILTER_ACTIVE}`);
      if ($active.length > 0) {
        const $btn = $(event.currentTarget);
        this.positionOptions($active, $btn);
      }
    }
  }

  /**
   * [bindResultsTabBack find the first visible result, if there is a filter open and there are filters applied then bind first result to handle tab back]
   * @return {[type]} [void]
   */
  bindResultsTabBack() {
    //if there are filters handle first selected result item
    this.$removeFilter.off(EVENTS.KEYDOWN);
    this.$cards.off(EVENTS.KEYDOWN);

    if (this.filterCount > 0) {
      this.$removeFilter.filter(':visible').first().on(EVENTS.KEYDOWN, e => this.handleTabBackIntoFilter(e));
    }
    else {
      const firstResult = this.$cards.first();
      if (firstResult) {
        firstResult.on(EVENTS.KEYDOWN, e => this.handleTabBackIntoFilter(e));
      }
    }
    //if there are not ftlers handle first result item card thing
  }

  handleTabBackIntoFilter(event) {
    if (Utils.isTabBack(event)) {
      event.preventDefault();
      const $last = this.$buttons.last();
      const category = $last.data(DATA.CATEGORY);
      const $optionGroup = this.$element.find(`${SELECTORS.FILTEROPTIONS}[data-category="${category}"]`);
      if (($optionGroup).hasClass(CLASSES.FILTER_ACTIVE)) {
        $optionGroup.find('input').last().focus();
      }
      else {
        $last.focus();
      }
    }
  }

  /**
   * [handleFilterButtonTab handles the tab event for a filter button]
   * @param  {[type]} event [keydown event]
   * @return {[type]}       [void]
   */
  handleFilterButtonTab(event) {
    if (Utils.isTab(event)) {
      this.handleFilterButtonForward(event);
    }

    //if back, get previous sibling, check to see if previous sibling's options are open, if there are then selected the last one
    if (Utils.isTabBack(event)) {
      this.handleFilterButtonBack(event);
    }
  }

  /**
   * [handleFilterButtonForward check to see if there is another js-filter-btn after]
   * @param  {[type]} event [keydown event]
   * @return {[type]}       [void]
   */
  handleFilterButtonForward(event) {
    //are we on a button with an open filter?
    const category = $(event.target).data(DATA.CATEGORY);
    const $optionGroup = this.$element.find(`${SELECTORS.FILTEROPTIONS}[data-category="${category}"]`);

    if (($optionGroup).hasClass(CLASSES.FILTER_ACTIVE)) {
      $optionGroup.find('input').first().focus();
      event.preventDefault();
    }
    else {
      const $nextBtn = $(event.currentTarget).next(SELECTORS.NAV_ITEM).find(SELECTORS.TRIGGER);

      if ($nextBtn.length > 0) {
        $nextBtn.focus();
        event.preventDefault();
      }
      else {
        const $focusable = $(':focusable');
        let next = $(':focusable').index(event.target) + 1;

        if (next > 0) {
          let item = $focusable.eq(next);

          while (item.hasClass('checkbox-input')) {
            next++;
            item = $focusable.eq(next);
          }

          item.focus();
          event.preventDefault();
        }
      }
    }
  }

  /**
   * [handleFilterButtonBack check to see if there is a optionGroup open ad focus the last input if there is]
   * @param  {[type]} event [keydown event]
   * @return {[type]}       [void]
   */
  handleFilterButtonBack(event) {
    const $prevBtn = $(event.currentTarget).prev(SELECTORS.NAV_ITEM);

    //if there is no previous, let default handle the rest
    if ($prevBtn.length > 0) {
      const prevCategory = $prevBtn.find(SELECTORS.TRIGGER).data(DATA.CATEGORY);
      const $optionGroup = this.$element.find(`${SELECTORS.FILTEROPTIONS}[data-category="${prevCategory}"]`);

      if (($optionGroup).hasClass(CLASSES.FILTER_ACTIVE)) {
        $optionGroup.find('input').last().focus();
        event.preventDefault();
      }
    }
  }

  /**
   * [handleCheckboxTabForward only called on last one in a group, so find matching category button, find next sibling and focus it if found]
   * @param  {[type]} event [keydown event]
   * @return {[type]}       [void]
   */
  handleCheckboxTabForward(event) {
    if (!Utils.isTab(event)) return;
    const category = $(event.currentTarget).closest(SELECTORS.FILTEROPTIONS).data(DATA.CATEGORY);
    const $btn = this.$element.find(`${SELECTORS.TRIGGER}[data-category="${category}"]`);
    const $sibling = $btn.parent().next().children(SELECTORS.TRIGGER);

    if ($sibling.length > 0) {
      $sibling.focus();
      event.preventDefault();
    }
  }

  /**
   * [handleCheckboxTabBack only called on first item in a group, find matching category button and focus that]
   * @param  {[type]} event [keydown event]
   * @return {[type]}       [void]
   */
  handleCheckboxTabBack(event) {
    if (!Utils.isTabBack(event)) return;

    const category = $(event.currentTarget).closest(SELECTORS.FILTEROPTIONS).data(DATA.CATEGORY);
    const $btn = this.$element.find(`${SELECTORS.TRIGGER}[data-category="${category}"]`);

    $btn.focus();
    event.preventDefault();
  }

  /**
   * [positionOptions positions the options dropdown to align with the button]
   * @param  {[type]} $options [the options dropdown to align]
   * @param  {[type]} $button  [the button that was clicked and to align to]
   * @return {[type]}          [void]
   */
  positionOptions($options, $button) {
    if (Utils.isDesktopViewport()) {
      $options.css('left', $button.closest(SELECTORS.NAV_ITEM).position().left);
    }
    else {
      $options.css('left', 0);
    }
  }

  /**
   * [clearFilters removes all selected filters]
   * @return {[type]} [void]
   */
  clearFilters() {
    this.$element.find(':checked').prop('checked', false).change();
  }

  /**
   * [handleRemoveFilterClick handles removing the selected filter buttons]
   * @param  {[type]} event [the click event]
   * @return {[type]}       [void]
   */
  handleRemoveFilterClick(event) {
    const $filter = $(event.currentTarget).closest(SELECTORS.FILTER_SELECTED_ITEM);
    const id = $filter.data(DATA.ID);
    this.$element.find(`#${id}`).prop('checked', false).change();
  }

  /**
   * [removeFilter removes a single filter and updates the view]
   * @param  {[type]} value    [the filter value, ex: winter]
   * @param  {[type]} category [the filter category, ex: season]
   * @return {[type]}          [void]
   */
  removeFilter(value, category) {
    if (!this.filters[category]) return;

    const idx = this.filters[category].indexOf(value);
    if (idx > -1) {
      this.filters[category].splice(idx, 1);
      this.filterCount--;
    }

    // Remove empty categories completely
    if (this.filters[category].length <= 0) {
      delete this.filters[category];
    }

    this.hideSelectedFilterItem(value);

    this.processFilters();
  }

  /**
   * [addFilter adds a single filter and updates the view]
   * @param  {[type]} value    [the filter value, ex: winter]
   * @param  {[type]} category [the filter category, ex: season]
   * @return {[type]}          [void]
   */
  addFilter(value, category) {
    if (!this.filters[category]) {
      this.filters[category] = [];
    }

    this.showSelectedFilterItem(value);

    this.filters[category].push(value);
    this.filterCount++;

    this.processFilters();
  }

  /**
   * '[showSelectedFilterItem shows the remove filter tag]'
   * @param  {[type]} id [the filter id from Ingeniux]
   * @return {[type]}    [void]
   */
  showSelectedFilterItem(id) {
    this.$selectedPanel.show();
    this.$clearFilters.show();

    const $selected = this.$element.find(`${SELECTORS.FILTER_SELECTED_ITEM}[data-id="${id}"]`);
    if ($selected) {
      $selected.addClass(CLASSES.SELECTED_FILTER_ACTIVE);
    }
  }

  /**
   * '[hideSelectedFilterItem hides the remove filter tag]'
   * @param  {[type]} id [the filter id from Ingeniux]
   * @return {[type]}    [void]
   */
  hideSelectedFilterItem(id) {
    const $selected = this.$element.find(`${SELECTORS.FILTER_SELECTED_ITEM}[data-id="${id}"]`);
    if ($selected) {
      $selected.removeClass(CLASSES.SELECTED_FILTER_ACTIVE);
    }

    if (this.filterCount === 0) {
      this.$selectedPanel.hide();
    }
  }

  /**
   * [processFilters shows or hides items depending on the selected filters]
   * @return {[type]} [void]
   */
  processFilters() {
    this.shownCards = [];
    this.isFirstFilter = true;

    Object.keys(this.filters).forEach((category, idx) => {
      this.processCategoryMatches(category, idx);
    });

    this.$cards.each((idx, card) => {
      if (this.filterCount !== 0) {
        if (this.shownCards.includes(idx)) {
          this.showCard(card);
        }
        else {
          this.hideCard(card);
        }
      }
      else {
        this.showCard(card);
      }
    });

    this.checkForZeroSet();
    this.bindResultsTabBack();
  }

  checkForZeroSet() {
    if (this.shownCards.length === 0 && this.filterCount !== 0) {
      this.$zeroMessage.show();
    } else {
      this.$zeroMessage.hide();
    }
  }

  /**
   * [showCard shows a card]
   * @param  {[type]} card [the card DOM  element]
   * @return {[type]}      [void]
   */
  showCard(card) {
    $(card).show();
  }

  /**
   * [hideCard hides a card]
   * @param  {[type]} card [the card DOM element]
   * @return {[type]}      [void]
   */
  hideCard(card) {
    $(card).hide();
  }

  /**
   * [processCategoryMatches shows or hides items for a particular category of filters]
   * @param  {[type]} category [the category name]
   * @param  {[type]} categoryIdx [position of the category in the filters obj]
   * @return {[type]}          [void]
   */
  processCategoryMatches(category, categoryIdx) {
    let newCards;
    const firstCategory = categoryIdx === 0;
    const matchesAny = this.categoryBehaviors[category] === 'MatchesAny';

    // If first filter category, filter all cards
    if (firstCategory) {
      newCards = this.$cards.map((cardIdx, card) => {
        if (this.hasAnyCategoryMatches(card, category)) return cardIdx;
      }).filter(i => i >= 0);
    }
    // If second filter category, filter shown set
    else {
      if (matchesAny) {
        newCards = this.shownCards.filter(cardIdx => this.hasAnyCategoryMatches(this.$cards[cardIdx], category));
      }
      else {
        newCards = this.shownCards.filter(cardIdx => this.hasAllCategoryMatches(this.$cards[cardIdx], category));
      }
    }

    this.shownCards = Array.from(newCards);
  }

  /**
   * [getCategoryMatches checks all tags of a category against a card]
   * @param  {[type]} card [the card]
   * @param  {[type]} category [the category]
   */
  getCategoryMatches(card, category) {
    const filters = this.filters[category];
    const data = String($(card).data(DATA.TAGS));
    
    return filters.filter(catId => data && data.includes(catId));
  }

  /**
   * [hasAnyCategorMatches matches a card with any matches of a category]
   * @param  {[type]} card [the card]
   * @param  {[type]} category [the category]
   */
  hasAnyCategoryMatches(card, category) {
    const matches = this.getCategoryMatches(card, category);

    return matches.length > 0;
  }

  /**
   * [hasAllCategorMatches matches a card with all matches of a category]
   * @param  {[type]} card [the card]
   * @param  {[type]} category [the category]
   */
  hasAllCategoryMatches(card, category) {
    const matches = this.getCategoryMatches(card, category);

    return matches.length === this.filters[category].length;
  }

  /**
   * [handleCheckboxChange handles the checkbox change, updates the filters and view]
   * @param  {[type]} event [the change event]
   * @return {[type]}       [void]
   */
  handleCheckboxChange(event) {
    const $target = $(event.currentTarget);
    const $input = $target.find('input');
    const value = $input.val();
    const $options = $target.closest(SELECTORS.FILTEROPTIONS);
    const category = $options.data(DATA.CATEGORY);
    const behavior = $options.data(DATA.BEHAVIOR);

    this.addCategoryBehavior(category, behavior);

    if ($input.prop('checked')) {
      this.addFilter(value, category);
    }
    else {
      this.removeFilter(value, category);
    }
  }

  /**
   * [handleButtonClick toggles the visibility of the options dropdown]
   * @param  {[type]} event [click event]
   * @return {[type]}       [void]
   */
  handleButtonClick(event) {
    event.preventDefault();
    const $target = $(event.currentTarget);
    const category = $target.data(DATA.CATEGORY);
    const $options = this.$element.find(`${SELECTORS.FILTEROPTIONS}[data-category="${category}"]`);

    this.positionOptions($options, $target);

    if ($options.hasClass(CLASSES.FILTER_ACTIVE)) {
      this.closeActiveFilter();
    }
    else if ($options) {

      this.openFilter($options);
      const $nav = $target.closest(SELECTORS.NAV_ITEM);
      $nav.addClass(CLASSES.NAV_ACTIVE);
      $nav.find(SELECTORS.ICON).removeClass(CLASSES.CLOSED).addClass(CLASSES.OPEN);
    }
  }

  /**
   * [openFilter closes any open dropdowns and opens the one passed in]
   * @param  {[type]} $options [the dropdown to show]
   * @return {[type]}          [void]
   */
  openFilter($options) {
      this.closeActiveFilter();
      $options.addClass(CLASSES.FILTER_ACTIVE);
      $options.find('input').first().focus();
  }

  /**
   * [closeActiveFilter closes the currently open dropdown]
   * @return {[type]} [void]
   */
  closeActiveFilter() {
    const $active = this.$element.find(`.${CLASSES.FILTER_ACTIVE}`);

    if ($active) {
      $active.removeClass(CLASSES.FILTER_ACTIVE);
      const $navActive = this.$element.find(`.${CLASSES.NAV_ACTIVE}`);
      $navActive.removeClass(CLASSES.NAV_ACTIVE);
      $navActive.find(SELECTORS.ICON).removeClass(CLASSES.OPEN).addClass(CLASSES.CLOSED);
    }
  }

  /**
   * [addCategoryBehavior created a category behavior lookup table. Used to handle MatchesAny/MatchesAll behavior ]
   * @param {[type]} category [the category, ex: season]
   * @param {[type]} behavior [the behavior, ex: MatchesAll]
   */
  addCategoryBehavior(category, behavior) {
    if (this.categoryBehaviors[category]) return;
    this.categoryBehaviors[category] = behavior;
  }
}
