/*
 * @fileOverview FormView module definition
 */

import View from 'views/View';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
    'FIELD': '.js-field',
    'MESSAGE': '.js-error-message',
    'CHECKBOXES': '[type="checkbox"], [type="radio"]',
    'TEXT_FIELDS': 'input, textarea, select',
    'DROPDOWN_TRIGGER': '.js-dropdown-trigger',
    'DROPDOWN_HIDDEN_INPUT': '.js-dropdown-hidden',
    'DROPDOWN_BTN': '.js-dropdown-list-item-btn',
    'FORMBUILDER_INPUTS': '.js-formBuilder-inputs',
    'SUCCESS_MESSAGE': '.js-formBuilder-successMessage',
    'ERROR_MESSAGE': '.js-formBuilder-errorMessage',
    'SUBMIT_BUTTON': 'button[type="submit"]',
    'RESPONSE_TEXT': '.js-responseText'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
    'FIELD_ERROR': 'field_error',
    'DROPDOWN_ERROR': 'dropdown-trigger_error',
    'DISABLE_SUBMIT': 'btn_primaryDisabled'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
    'SUBMIT': 'submit',
    'FOCUS_OUT': 'focusout',
    'CHANGE': 'change',
    'CLICK': 'click'
};

/**
 * A reference to the data attributes used in this view
 *
 * @property DATA
 * @type {Object}
 * @private
 */
const DATA = {
    'REQUIRED_MESSAGE': 'required-message',
    'EMAIL_MESSAGE': 'email-message'
};

/**
 * A collection of validation data and tests
 *
 * @property VALIDATORS
 * @type {Object}
 * @private
 */
const VALIDATORS = {
    'REQUIRED': {
        'attribute': DATA.REQUIRED_MESSAGE,
        'test': function (value) {
            const trimmed = value.trim();

            return trimmed !== '';
        },
        'message': 'This field is required.'
    },
    'EMAIL': {
        'attribute': DATA.EMAIL_MESSAGE,
        'test': function (value) {
            return /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
        },
        'message': 'Please enter a valid email address.'
    },
    'CHECKBOX': {
        'test': function ($group) {
            return $group.filter(':checked').length > 0;
        },
        'message': 'Please select an option.'
    }
}

/**
 * Submits newsletter or other generic forms to correct service
 *
 * @class FormView
 * @constructor
 */
export default class FormView extends View {
    /**
     * Create any child objects or references to DOM elements.
     *
     * @method createChildren
     * @returns {FormView}
     * @public
     * @chainable
     */
    createChildren() {
        this.$checkboxes = this.$element.find(SELECTORS.CHECKBOXES);
        this.$textfields = this.$element.find(SELECTORS.TEXT_FIELDS).not(SELECTORS.CHECKBOXES);
        this.$dropdownBtns = this.$element.find(SELECTORS.DROPDOWN_BTN);
        this.$errorMessage = this.$element.find(SELECTORS.ERROR_MESSAGE);
        this.$successMessage = this.$element.find(SELECTORS.SUCCESS_MESSAGE);
        this.$submitBtn = this.$element.find(SELECTORS.SUBMIT_BUTTON);
        this.$responseText = this.$element.find(SELECTORS.RESPONSE_TEXT);

        return this;
    }

    /**
     * Enable event handlers.
     * Exits early if component already enabled.
     *
     * @method enable
     * @returns {FormView}
     * @chainable
     * @public
     */
    enable() {
        this.$element.on(EVENTS.SUBMIT, e => this.handleFormSubmit(e));
        this.$checkboxes.on(EVENTS.CHANGE, e => this.handleCheckboxChange(e));
        this.$textfields.on(EVENTS.FOCUS_OUT, e => this.handleFieldBlur(e));
        this.$dropdownBtns.on(EVENTS.CLICK, e => this.handleDropdownChange(e));

        this.moveHiddenInputs();
        return this;
    }

    /**
     * [moveHiddenInputs FormBuilder from IGX puts these outside of the form, move them back]
     * @return {[type]} [void]
     */
    moveHiddenInputs() {
        this.$element.append(this.$element.siblings(SELECTORS.FORMBUILDER_INPUTS));
    }

    /**
     * Handles an event from a checkbox state change and passes to validate
     *
     * @method handleCheckboxChange
     * @param {Event} event JavaScript event object
     */
    handleCheckboxChange(event) {
        this.validateCheckbox($(event.currentTarget));
    }

    /**
     * Handles an event from a field focus changes and passes to validate
     *
     * @method handleFieldBlur
     * @param {Event} event JavaScript event object
     */
    handleFieldBlur(event) {
        this.validateTextField($(event.currentTarget));
    }

    /**
     * Handles an event from a Dropdown Button click and removes the
     * error state if there is one on the field
     *
     * @method handleDropdownChange
     * @param {Event} event JavaScript event object
     */
    handleDropdownChange(event) {
        const $field = $(event.currentTarget).closest(SELECTORS.FIELD);
        const $input = $field.find(SELECTORS.DROPDOWN_HIDDEN_INPUT);

        if ($input.val() !== '') {
            this.removeError($field);
        }
    }

    /**
     * Handle form submit
     *
     * @method handleFormSubmit
     * @param {Event} JavaScript event object
     * @public
     */
    handleFormSubmit(event) {
        event.preventDefault();

        if (this.processing) return false;

        this.processing = true;
        this.$submitBtn.addClass(CLASSES.DISABLE_SUBMIT);
        this.$submitBtn.attr("disabled", true);

        if (this.validateForm()) {
            const data = this.$element.serialize();
            const url = this.$element.attr('action');
            const settings = {
                'data': data,
                'error': (response) => {
                    this.showFail(response.statusText);
                },
                'success': () => {
                    this.showSuccess();
                },
                'type': 'POST',
                'url': url
            };

            this.$submitBtn.addClass(CLASSES.DISABLE_SUBMIT);
            this.$submitBtn.attr("disabled", true);
            $.ajax(settings);
        } else {
            this.$submitBtn.removeClass(CLASSES.DISABLE_SUBMIT);
            this.$submitBtn.removeAttr("disabled");
        }

        this.processing = false;

        return false;
    }

    /**
     * [showSuccess shows the success message for the form submission]
     * @return {[type]} [void]
     */
    showSuccess() {
        this.$errorMessage.hide();
        this.$successMessage.show();
    }

    /**
     * [showFail  shows the error message for the form submission]
     * @return {[type]} [void]
     */
    showFail(responseText) {
        this.$successMessage.hide();
        this.$errorMessage.show();
        this.$submitBtn.removeClass(CLASSES.DISABLE_SUBMIT);
        this.$submitBtn.removeAttr("disabled");
        if (this.$responseText.length > 0) {
            this.$responseText.text(responseText);
        }
    }

    /**
     * Resets error counter and calls validation methods.
     *
     * @method validateForm
     * @return {Bool} whether or not there are errors on the form
     */
    validateForm() {
        this.errors = 0;

        this.validateTextFields();
        this.validateCheckboxes();

        return this.errors === 0;
    }

    /**
     * Loops through all text fields and validates each one
     *
     * @method validateTextFields
     */
    validateTextFields() {
        this.$textfields.each((i, el) => this.validateTextField($(el)));
    }

    /**
     * Loops through all checkboxes and validates each one
     *
     * @method validateCheckboxes
     */
    validateCheckboxes() {
        this.$checkboxes.each((i, el) => this.validateCheckbox($(el)));
    }

    /**
     * Validates a text field for presence and if it is an email field,
     * for validity. Updates error tracking if field fails
     *
     * @method validateTextField
     * @param {Object} $el jQuery field selector
     */
    validateTextField($el) {
        const $field = $el.closest(SELECTORS.FIELD);
        const isRequired = $el.attr('required') !== undefined;
        const isEmail = $el.attr('type') === 'email';
        const isDirty = VALIDATORS.REQUIRED.test($el.val());

        if (isRequired && !isDirty) {
            this.trackError($el.closest(SELECTORS.FIELD), VALIDATORS.REQUIRED);
        } else if (
            isEmail &&
            isDirty &&
            !VALIDATORS.EMAIL.test($el.val())
        ) {
            this.trackError($el.closest(SELECTORS.FIELD), VALIDATORS.EMAIL);
        } else {
            this.removeError($field);
        }
    }

    /**
     * Validates a checkbox or radio for presence
     *
     * @method validateCheckbox
     * @param {Object} $el jQuery field selector
     */
    validateCheckbox($el) {
        const $field = $el.closest(SELECTORS.FIELD);
        const $group = this.$element.find(`[name="${$el.attr('name')}"]`);
        const $required = $group.filter('[required]');
        const isRequired = $required.length > 0;
        let isValid = VALIDATORS.CHECKBOX.test($group);

        if (isRequired && !isValid) {
            this.trackError($field, VALIDATORS.CHECKBOX);
        } else if (isRequired && isValid) {
            this.removeError($field);
        }
    }

    /**
     * Increments error tracker and shows an error.
     *
     * @method trackError
     * @param {Object} $field jQuery field selector
     * @param {String} message Error message to be shown
     */
    trackError($field, validator) {
        this.errors++;
        this.showError($field, validator);
    }

    /**
     * Show error
     *
     * @method showError
     * @param {Validator} Object the Validator
     * @param {$field} jQuery object
     * @public
     */
    showError($field, validator) {
        const $textField = $field.find(SELECTORS.MESSAGE);
        const message = $textField.data(validator.attribute) || validator.message;
        this.showErrorMessage($field, message);
    }

    /**
     * Show error
     *
     * @method showError
     * @param {Message} String error message
     * @param {$field} jQuery object
     * @public
     */
    showErrorMessage($field, message) {
        const $dropdownTrigger = $field.find(SELECTORS.DROPDOWN_TRIGGER);

        $field.addClass(CLASSES.FIELD_ERROR);
        const $textField = $field.find(SELECTORS.MESSAGE);
        $textField.text(message);

        if ($dropdownTrigger.length > 0) {
            $dropdownTrigger.addClass(CLASSES.DROPDOWN_ERROR);
        }
    }

    /**
     * Remove error
     *
     * @method removeError
     * @param {$field} jQuery object
     * @public
     */
    removeError($field) {
        const $dropdownTrigger = $field.find(SELECTORS.DROPDOWN_TRIGGER);

        $field.removeClass(CLASSES.FIELD_ERROR);
        $field.find(SELECTORS.MESSAGE).text('');

        if ($dropdownTrigger.length > 0) {
            $dropdownTrigger.removeClass(CLASSES.DROPDOWN_ERROR);
        }
    }
}