/*
 * @fileOverview FlyoutView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'BODY': 'body',
  'DISMISS_BUTTON': '.js-dismissButton',
  'INNER_CONTENT': '.js-flyout-inner-content'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'NO_SCROLL': 'noScroll',
  'SHOW': 'flyout_show',
  'FLYOUT_IOS': 'flyout_ios'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'KEYDOWN': 'keydown'
};

/**
 * A reference to the sizes of elements used in this view
 *
 * @property SIZES
 * @type {Object}
 * @private
 */
const SIZES = {
  'HEADER': 68,
  'FOOTER': 83
};

/**
 * Shows/hides a fullscreen takeover similar to a modal
 * Used with Datepicker on mobile
 *
 * @class MobileFlyoutView
 */
export default class MobileFlyoutView extends View {
  constructor($element, options = {}) {
    super($element);

    this.afterHide = options.afterHide;
    this.afterShow = options.afterShow;
  }

  afterInit() {
    this.pickerOpen = false
    this.scrollTop = 0;

    if (Utils.isIosSafari()) {
      this.$element.addClass(CLASSES.FLYOUT_IOS);
    }

    return this;
  }

  setInnerHeight() {
    if (Utils.isLargeDesktopViewport()) return;
    
    const windowHeight = Utils.isIosSafari() ? $(this.$window).height() : this.$window[0].innerHeight;
    const total = windowHeight - SIZES.HEADER - SIZES.FOOTER;

    this.$innerContent.css('height', total);
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {MobileFlyoutView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$window = $(window);
    this.$body = $(SELECTORS.BODY);
    this.$dismissButton = this.$element.find(SELECTORS.DISMISS_BUTTON);
    this.$innerContent = this.$element.find(SELECTORS.INNER_CONTENT);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {MobileFlyoutView}
   * @chainable
   * @public
   */
  enable() {
    this.$dismissButton.on(EVENTS.CLICK, () => this.hide());

    return this;
  }

  handleToggle(e) {
    if (e.type === EVENTS.KEYDOWN) {
      if (e.which === 13) {
        e.preventDefault();

        this.toggle();
      }
    }
    else {
      this.toggle();
    }
  }

  toggle() {
    if (this.pickerOpen) {
      this.hide();
    }
    else {
      this.show();
    }
  }

  show() {
    if (this.pickerOpen) return;
    this.pickerOpen = true;

    // We need to set the height before showing to
    // account for IOS/Android's difference in header/footer/menu
    // bars added to their browsers
    this.setInnerHeight();

    this.scrollTop = this.$window.scrollTop();
    this.$element.addClass(CLASSES.SHOW);
    this.$element.attr('aria-hidden', 'false');

    if (!Utils.isLargeDesktopViewport()) {
      this.$body.addClass(CLASSES.NO_SCROLL);
    }

    if (this.afterShow) this.afterShow();
  }

  hide() {
    if (!this.pickerOpen) return;

    this.pickerOpen = false;

    if (!Utils.isLargeDesktopViewport()) {
      this.$body.removeClass(CLASSES.NO_SCROLL);
      this.$window.scrollTop(this.scrollTop);
    }

    this.$element.removeClass(CLASSES.SHOW).attr('aria-hidden', 'true');

    if (this.afterHide) this.afterHide();
  }

  isOpen() {
    return this.pickerOpen;
  }
}
