/*
 * @fileOverview UnitsToggle module definition
 */

import View from 'views/View';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'MEASUREMENT': '.js-measurement',
  'UNIT': '.js-measurement-unit',
  'FAHRENHEIT': '.js-fahrenheit',
  'CELCIUS': '.js-celcius'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'ACTIVE': 'btnToggle_active'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

/**
 * A reference to the data used in this view
 *
 * @property DATA
 * @type {Object}
 * @private
 */
const DATA = {
  'METRIC': 'metric',
  'USC': 'usc'
};

/**
 * A reference to the constants used in this view
 *
 * @property CONSTANTS
 * @type {Object}
 * @private
 */
const CONSTANTS = {
  'DEGREE': '&deg;'
};

/**
 * Handles toggling between METRIC and USC values
 *
 * @class UnitsToggle
 */
export default class UnitsToggle extends View {

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {UnitsToggle}
   * @public
   * @chainable
   */
  createChildren() {
    this.$celciusTrigger = this.$element.find(SELECTORS.CELCIUS);
    this.$fahrenheitTrigger = this.$element.find(SELECTORS.FAHRENHEIT);
    this.$measurements = $(SELECTORS.MEASUREMENT);
    this.$units = $(SELECTORS.UNIT);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {UnitsToggle}
   * @chainable
   * @public
   */
  enable() {
    this.$celciusTrigger.on(EVENTS.CLICK, e => this.HandleMetricClick(e));
    this.$fahrenheitTrigger.on(EVENTS.CLICK, e => this.handleUscClick(e));

    return this;
  }

  getUnitDisplay(choice, unitType) {
    switch (choice) {
      case 'yes':
        return `${CONSTANTS.DEGREE}${unitType}`;
      case 'degreeOnly':
        return CONSTANTS.DEGREE;
      default:
        return '';
    }
  }

  HandleMetricClick() {
    this.$fahrenheitTrigger.removeClass(CLASSES.ACTIVE);
    this.$celciusTrigger.addClass(CLASSES.ACTIVE);

    this.$measurements.each((idx, item) => {
      const $item = $(item);
      if ($item.data('metric')) {
        $item.html($item.data('metric'));
      }
    });

    this.$units.each((idx, item) => {
      const $item = $(item);
      $item.html($item.data('metric'));
    });
  }

  handleUscClick() {
    this.$celciusTrigger.removeClass(CLASSES.ACTIVE);
    this.$fahrenheitTrigger.addClass(CLASSES.ACTIVE);
    this.$measurements.each((idx, item) => {
      const $item = $(item);
      if ($item.data('usc')) {
        $item.html($item.data('usc'));
      }
    });

    this.$units.each((idx, item) => {
      const $item = $(item);
      $item.html($item.data('usc'));
    });
  }

  convertToCelcius(fahrenheit) {
    return Math.round((fahrenheit - 32) / 1.8);
  }
}
