/*
 * @fileOverview ScrollableHList module definition
 * Wrap a .hList .mix-hList_scrollabe with this module if you
 * want to add the left/right fade elements
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'LIST': '.mix-hList_scrollable'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'FADE_LEFT': 'mix-scrollable-fade_left',
  'FADE_RIGHT': 'mix-scrollable-fade_right'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'SCROLL': 'scroll'
};

/**
 * Container object for storing times
 *
 * @constant TIMES
 * @type {Object}
 */
const TIMES = {
  'THROTTLE': 25
};

/**
 * Allows for a scrolling Horizontal list
 *
 * @class ScrollableHList
 */
export default class ScrollableHList extends View {

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ScrollableHList}
   * @chainable
   * @public
   */
  enable() {
    this.width = this.$list.width();
    this.scrollWidth = this.$list.get(0).scrollWidth;
    this.$list.on(EVENTS.SCROLL, Utils.throttle(this.addFades.bind(this), TIMES.THROTTLE));
    this.addFades();

    return this;
  }

  createChildren() {
    this.$list = this.$element.find(SELECTORS.LIST);

    return this;
  }

  addFades() {
    if (this.canScrollLeft()) {
      this.$element.addClass(CLASSES.FADE_LEFT);
    }
    else {
      this.$element.removeClass(CLASSES.FADE_LEFT);
    }

    if (this.canScrollRight()) {
      this.$element.addClass(CLASSES.FADE_RIGHT);
    }
    else {
      this.$element.removeClass(CLASSES.FADE_RIGHT);
    }
  }

  canScrollLeft() {
    return this.$list.scrollLeft() !== 0;
  }

  canScrollRight() {
    const scrollLeft = this.$list.scrollLeft();

    return this.scrollWidth - scrollLeft !== this.width;
  }
}
