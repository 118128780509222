/*
 * @fileOverview SearchableResults module definition
 */

import View from 'views/View';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'SEARCH': '.js-search-trigger',
  'RESULTS': '.js-search-result',
  'AUTOCOMPLETE': '.js-search-autocomplete',
  'POSITION': '.js-autocomplete_position',
  'CONTAINER': '.js-autcomplete-container',
  'SHOWALL': '.js-search-showall'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'KEYDOWN': 'keyup',
  'CLICK': 'click',
  'INPUT': 'input'
};

/**
 * Handles initializing the autocomplete
 *
 * @class SearchableResults
 */
export default class SearchableResults extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {SearchableResults}
   * @public
   * @chainable
   */
  createChildren() {
    this.$search = this.$element.find(SELECTORS.SEARCH);
    this.$autoCompletes = this.$element.find(SELECTORS.AUTOCOMPLETE);
    this.$results = this.$element.find(SELECTORS.RESULTS);
    this.$showAll = this.$element.find(SELECTORS.SHOWALL);
    this.$candidates = this.$autoCompletes.map(function() {
      return $(this).data("search-autocomplete");
    });

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {SearchableResults}
   * @chainable
   * @public
   */
  enable() {
    this.$search.autocomplete({
      'appendTo': SELECTORS.CONTAINER,
      'classes': {
        'ui-autocomplete': 'ui-autocomplete ui-autocomplete_custom'
      },
      'source': this.$candidates.get(),
      'position': { my : "left top", at: "left bottom", of: SELECTORS.POSITION }
    });
    this.$search.on(EVENTS.INPUT,  e => this.filterItems(e));

    if (this.$showAll.length > 0) {
      this.$showAll.on(EVENTS.CLICK, e => this.handleShowAllClick(e));
    }

    return this;
  }
 

  filterItems(e) {
    if (this.$search.length <= 0) return;

    const terms = this.$search.val().toLowerCase();

    if (terms.length > 0) {
      if (this.$showAll.length > 0) {
        this.$showAll.hide();
      }
      this.$results.hide();
      this.$results.filter(`[data-search-text*="${terms}"]`).show();
    }
    else {
      this.$results.show();
      if (!this.noShowAll && this.$showAll.length > 0) {
        this.$showAll.hide();
      }
    }
  }

  handleShowAllClick(e) {
    this.noShowAll = true;
    this.$showAll.hide();
    this.$results.show();
    this.filterItems();
  }
}
