/*
 * @fileOverview ConditionsDrawerView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils'
/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'CLOSE': '.js-conditionsDrawer-close',
  'DRAWER': '.js-conditionsDrawer',
  'TRIGGER': '.js-navConditions',
  "FOCUS": '.js-conditionsDrawer-focus',
  'SEARCH_TRIGGER': '.js-search-trigger'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'DRAWER_ACTIVE': 'conditionsDrawer_active',
  'DRAWER_NAV': 'navConditions',
  'DRAWER_NAV_ACTIVE': 'navConditions_active',
  'NAV': 'nav'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

/**
 * Toggles the ConditionsDrawerView
 *
 * @class NavView
 */
export default class ConditionsDrawerView extends View {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);
  }

  /**
   * Binds the scope of any handler functions.
   *
   * @method setupHandlers
   * @returns {NavView}
   * @public
   * @chainable
   */
  setupHandlers() {
    this.closeDrawer = this.closeDrawer.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);

    return this;
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {NavView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$window = $(window);
    this.$closeTrigger = $(SELECTORS.CLOSE);
    this.$drawer = $(SELECTORS.DRAWER);
    this.$navTrigger = $(SELECTORS.TRIGGER);
    this.$focus = $(SELECTORS.FOCUS);
    this.$searchTrigger = $(SELECTORS.SEARCH_TRIGGER);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ConditionsDrawerView}
   * @chainable
   * @public
   */
  enable() {
    this.$window.on(EVENTS.CLICK, this.closeDrawer);
    this.$closeTrigger.on(EVENTS.CLICK, this.closeDrawer);
    this.$searchTrigger.on(EVENTS.CLICK, this.closeDrawer);
    this.$element.on(EVENTS.CLICK, (e) => e.stopPropagation());
    this.$navTrigger.on(EVENTS.CLICK, this.handleClick);

    return this;
  }

  /**
   * Toggles the Drawer to opposite of current state
   *
   *
   * @method toggleDrawer
   * @public
   */
  handleClick(e) {
    if (Utils.isDesktopViewport()) {
      e.preventDefault();
      e.stopPropagation();
      this.toggleDrawer(e);
    }
  }

  /**
   * Toggles the Drawer to opposite of current state
   *
   *
   * @method toggleDrawer
   * @public
   */
  toggleDrawer(e) {
    if (!this.isDrawerOpen) {
      this.openDrawer();
    } else {
      this.closeDrawer(e);
    }
  }

  /**
   * Close the ConditionsDrawerView
   *
   *
   * @method closeDrawer
   * @public
   */
  closeDrawer(event) {
    const notInElement = $(event.target).closest(`.${CLASSES.DRAWER_NAV}`).length === 0;
    const notInNav = $(event.target).closest(`.${CLASSES.NAV}`).length === 0;
    const isToggle = $(event.target).parents(SELECTORS.TRIGGER).length > 0;

    if (notInElement && notInNav || isToggle) {
      this.$drawer.removeClass(CLASSES.DRAWER_ACTIVE);
      this.isDrawerOpen = false;
      this.$navTrigger.removeClass(CLASSES.DRAWER_NAV_ACTIVE);
    }
  }

  /**
    * Opens the ConsitionsDrawerView
   *
   * @method openDrawer
   */
  openDrawer() {
    this.$drawer.addClass(CLASSES.DRAWER_ACTIVE);
    this.isDrawerOpen = true;
    this.$navTrigger.addClass(CLASSES.DRAWER_NAV_ACTIVE);
    this.$focus.focus();
  }
}
