/*
 * @fileOverview ScrollTableView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'SCROLLABLE_LEFT': 'scrollwrapper-scrollableLeft',
  'SCROLLABLE_RIGHT': 'scrollwrapper-scrollableRight',
  'WRAPPER': 'scrollwrapper',
  'SCROLLER': 'scrollwrapper-scroller'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'SCROLL': 'scroll',
  'RESIZE': 'resize'
};

/**
 * Toggles subNav height and aria roles in an ScrollTableView view component
 *
 * @class ScrollTableView
 */
export default class ScrollTableView extends View {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ScrollTableView}
   * @chainable
   * @public
   */
  enable() {
    this.$element.attr('tabindex', '0');
    this.$element.wrap(`<div class="${CLASSES.WRAPPER}" />`).wrap(`<div class="${CLASSES.SCROLLER}" />`);
    this.$scrollerTarget = this.$element.parent();
    const callback = Utils.throttle(() => this.updateTableScroll(this.$scrollerTarget), 100);
    this.$scrollerTarget.on(EVENTS.SCROLL, callback);
    window.addEventListener(EVENTS.RESIZE, callback);
    this.updateTableScroll(this.$scrollerTarget);
    return this;
  }

   updateTableScroll($scrollerTarget) {
     const scrollLeft = $scrollerTarget.scrollLeft();
     const $wrapperTarget = $scrollerTarget.parent();

     const scrollRight = Math.abs(scrollLeft + $scrollerTarget.width() - $scrollerTarget.get(0).scrollWidth);
     const threshold = 5;

     // Determine if the left side of the table is visible.
     if (scrollLeft > threshold) {
       $wrapperTarget.addClass(CLASSES.SCROLLABLE_LEFT);
     } else {
       $wrapperTarget.removeClass(CLASSES.SCROLLABLE_LEFT);
     }

     // Determine if the right side of the table is visible.
     if (scrollRight > threshold) {
       $wrapperTarget.addClass(CLASSES.SCROLLABLE_RIGHT);
     } else {
       $wrapperTarget.removeClass(CLASSES.SCROLLABLE_RIGHT);
     }
   }
}
