/*
 * @fileOverview SingleLocationMapView module definition
 */

import View from 'views/View';
import PubSub from 'utils/PubSub';

const DATA = {
  'LATITUDE': 'latitude',
  'LONGITUDE': 'longitude',
  'APIKEY': 'apikey',
  'MAPTYPE': 'maptype',
  'ZOOM': 'zoom'
}

/**
 * Loads a map with the data on the element
 *
 * @class SingleLocationMapView
 */
export default class SingleLocationMapView extends View {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);
  }

  /**
   * Binds the scope of any handler functions.
   *
   * @method setupHandlers
   * @returns {SingleLocationMapView}
   * @public
   * @chainable
   */
  setupHandlers() {
    this.loadMapsApi = this.loadMapsApi.bind(this);
    this.loadMap = this.loadMap.bind(this);
    this.registerSubscriptions = this.registerSubscriptions.bind(this);

    return this;
  }

  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {SingleLocationMapView}
   * @public
   * @chainable
   */
  createChildren() {
    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {SingleLocationMapView}
   * @chainable
   * @public
   */
  enable() {
    this.loadMapsApi();
    this.registerSubscriptions();

    return this;
  }

  registerSubscriptions() {
    window.pubsub.subscribe('mapApiLoaded', data => {
      this.loadMap();
    });
  }

  /**
   * Loads the google map script
   *
   *
   * @method loadMapsApi
   * @public
   */
  loadMapsApi() {
    if (window.mapsLoaded) return;
    window.mapsLoaded = true;
    const key = this.$element.data(DATA.APIKEY);

    $.getScript(`https://maps.googleapis.com/maps/api/js?key=${key}&callback=onMapsApiLoaded`);
  }

  /**
   * Creates a new map from data attributes
   *
   *
   * @method loadMap
   * @public
   */
  loadMap() {
    const myLatLng = {
      lat: this.$element.data(DATA.LATITUDE),
      lng: this.$element.data(DATA.LONGITUDE)
    };

    const map = new google.maps.Map(this.$element[0], {
      center: myLatLng,
      zoom: this.$element.data(DATA.ZOOM),
      mapTypeId: this.$element.data(DATA.MAPTYPE)
    });

    const marker = new google.maps.Marker({
      map: map,
      position: myLatLng
    });
  }
}
