/*
 * @fileOverview BookingNavView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'MENU_BUTTON': '.js-menuButton',
  'MENU_ICON': '.js-menuIcon',
  'FORM': '.js-bookingForm',
  'HERO_MODULE': '.hero',
  'SUBMITTABLE_FORM': '.js-bookingProvider-form',
  'SUBMIT_BUTTON': '.js-footer-submitBooking'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'HIDDEN': 'displayNone',
  'ICON_CLOSED': 'mix-icon_rotate270',
  'ICON_OPEN': 'mix-icon_rotate90',
  'ICON_ALL': 'mix-icon_rotate270 mix-icon_rotate90',
  'BODY_WITH_NAV': 'body_withStickyNav'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'SCROLL': 'scroll',
  'FORCE_SUBMIT': 'force:submit',
  'RESIZE': 'resize'
};


 /**
 * Container object for storing times
 *
 * @constant TIMES
 * @type {Object}
 */
  const TIMES = {
    'THROTTLE': 25
  };

// Defualt distance user has to scroll to
// show the sticky nav
const DEFAULT_SCROLL_DISTANCE = 700;

/**
 * Shows/hides sticky Booking Nav
 *
 * @class BookingNavView
 */
export default class BookingNavView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {ExampleView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$window = $(window);
    this.$body = $('body');
    this.$heroModule = $(SELECTORS.HERO_MODULE);
    this.$form = this.$element.find(SELECTORS.FORM);
    this.$menuButton = this.$element.find(SELECTORS.MENU_BUTTON);
    this.$menuIcon = this.$element.find(SELECTORS.MENU_ICON);
    this.$mobileSubmit = this.$element.find(SELECTORS.SUBMIT_BUTTON);
    this.$submittableForm = this.$element.find(SELECTORS.SUBMITTABLE_FORM);

    return this;
  }

  /*
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit () {
    this.navOpen = false;
    this.formOpen = false;
    this.scrollThreshold = this.$heroModule.height() || DEFAULT_SCROLL_DISTANCE;

    if (this.isMobileViewport) this.showNav();

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ExampleView}
   * @chainable
   * @public
   */
  enable() {
    this.isMobileViewport = !Utils.isLargeDesktopViewport();

    if (!this.isMobileViewport) {
      this.$window.on(EVENTS.SCROLL, e => Utils.debounce(this.handleScroll(e)));
    }

    this.$menuButton.on(EVENTS.CLICK, () => this.handleMenuButtonClick());
    this.$mobileSubmit.on(EVENTS.CLICK, () => this.handleFormSubmit());
    this.$window.on(EVENTS.RESIZE, Utils.throttle(e => this.handleWindowResize(e), TIMES.THROTTLE));

    return this;
  }

  /**
   * Handles submitting the form. Have to force a submit of the other booking form on the page
   */
  handleFormSubmit() {
    this.$submittableForm.trigger({ 'type': EVENTS.FORCE_SUBMIT, 'exceptedDuplicates': '.js-mobile-inputs' });
  }

  /**
   * Handles click event of the openMenu button
   * Shows or hides the form container
   *
   * @method handleMenuButtonClick
   * @public
   */
  handleMenuButtonClick() {
    if (this.formOpen) {
      this.closeForm();
    }
    else {
      this.openForm();
    }
  }

   /**
   * Calls methods associated with a window resize
   *
   * @method handleWindowResize
   * @param {Event} event JavaScript event object
   * @public
   */
    handleWindowResize(event) {
      const resizedWindowWidth = this.$window.width();

      if (this.windowWidth === resizedWindowWidth) {
        return;
      }

      if (!Utils.isLargeDesktopViewport()) {
        this.closeForm();
      } else {
        this.openForm();
      }
      this.checkScrollPosition();
      this.windowWidth = resizedWindowWidth;
    }

  /**
   * Closes the form container
   *
   * @method closeForm
   * @public
   */
  closeForm() {
    this.$form.addClass(CLASSES.HIDDEN);
    this.$menuIcon.removeClass(CLASSES.ICON_ALL).addClass(CLASSES.ICON_CLOSED);

    this.formOpen = false;
  }

  /**
   * Opens the form container
   *
   * @method openForm
   * @public
   */
  openForm() {
    this.$form.removeClass(CLASSES.HIDDEN);
    this.$menuIcon.removeClass(CLASSES.ICON_ALL).addClass(CLASSES.ICON_OPEN);

    this.formOpen = true;
    }

  /**
   * Handles the window scroll event
   *
   * @method handleScroll
   * @public
   */
  handleScroll(e) {
    this.checkScrollPosition();
  }

  /**
   * Checks the current scroll position against
   * the height of the header, then shows or hides
   * the "nav"
   *
   * @method checkScrollPosition
   * @public
   */
  checkScrollPosition() {
    const current = this.$window.scrollTop();

    if (current < this.scrollThreshold && !$('body').hasClass('noScroll') && Utils.isLargeDesktopViewport()) {
      this.hideNav();
    }
    else {
      this.showNav();
    }
  }

  /**
   * Shows the nav
   *
   * @method showNav
   * @public
   */
  showNav() {
    if (this.navOpen) return;
    this.navOpen = true;

    this.$element.removeClass(CLASSES.HIDDEN);

    if (Utils.isLargeDesktopViewport()) {
      this.openForm();
    }
    else {
      this.$body.addClass(CLASSES.BODY_WITH_NAV);
    }
  }

  /**
   * Hides the nav
   * Collapses the internal form
   * Closes the form container
   *
   * @method hideNav
   * @public
   */
  hideNav() {
    if (!this.navOpen) return;
    this.navOpen = false;

    this.$element.addClass(CLASSES.HIDDEN);
    this.$body.removeClass(CLASSES.BODY_WITH_NAV);

    if (!Utils.isLargeDesktopViewport()) {
      this.closeForm();
    }
  }
}
