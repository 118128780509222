/*
 * @fileOverview LeftHandNavView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'TRIGGER': '.js-left-nav-trigger',
  'SELECTOR_TEXT': '.js-left-nav-selector-text',
  'LINKS': '.js-left-nav-links',
  'HERO': '.js-hero-image',
  'CONTENT': '.js-left-nav-content'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'ARROW_UP': 'mix-icon_rotate270',
  'ACTIVE': 'mix-content_leftHandNavActive'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'RESIZE': 'resize'
};

/**
 * Controls LeftHandNav look and behavior depending on viewport
 *
 * @class LeftHandNavView
 */
export default class LeftHandNavView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {LeftHandNavView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$body = $('html,body');
    this.$window = $(window);
    this.$trigger = this.$element.find(SELECTORS.TRIGGER);
    this.$triggerText = this.$trigger.children('span');
    this.$triggerIcon = this.$trigger.children('i');
    this.$links = this.$element.find(SELECTORS.LINKS);
    this.$selectorText = this.$element.find(SELECTORS.SELECTOR_TEXT);
    this.$hero = this.$body.find(SELECTORS.HERO);
    this.$content = this.$element.find(SELECTORS.CONTENT);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {LeftHandNavView}
   * @chainable
   * @public
   */
  enable() {
    this.$trigger.on(EVENTS.CLICK, e => this.handleTriggerClick(e));
    this.$window.on(EVENTS.RESIZE, e => Utils.debounce(this.handleWindowResize(e)));

    return this;
  }

  /**
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @method afterInit
   * @returns {LeftHandNavView}
   * @public
   */
  afterInit() {
    this.isNavOpen = false;
    this.windowWidth = this.$window.width();

    if (Utils.isLargeDesktopViewport()) {
      if (this.$hero.length > 0) {
        this.calculateTop();
      }
    }
    this.showLeftNav();
  }

  /**
   * Handle trigger click
   *
   * @method handleTriggerClick
   * @param {Object} event [Javascript event object]
   * @public
   */
  handleTriggerClick(e) {
    if (Utils.isLargeDesktopViewport()) {
      return;
    }

    if (this.isNavOpen) {
      this.closeNav();
    } else {
      this.openNav();
    }
  }

  /**
   * Handle window resize
   *
   * @method handleWindowResize
   * @param {Object} event [Javascript event object]
   * @public
   */
  handleWindowResize(e) {
    const resizedWindowWidth = this.$window.width();

    if (this.windowWidth === resizedWindowWidth) {
      return;
    }

    if (Utils.isLargeDesktopViewport()) {
      this.openNav();
      this.calculateTop();
    } else {
      this.closeNav();
      this.resetTop();
    }

    this.windowWidth = resizedWindowWidth;
  }

  /**
   * Open Left Hand Nav
   *
   * @method openNav
   * @public
   */
  openNav() {
    this.$links.show();
    this.$selectorText.css('opacity', '0');
    this.$triggerText.text('close');
    this.$triggerIcon.addClass(CLASSES.ARROW_UP);

    this.isNavOpen = true;
  }

  /**
   * Close Left Hand Nav
   *
   * @method closeNav
   * @public
   */
  closeNav() {
    this.$links.hide();
    this.$selectorText.css('opacity', '1');
    this.$triggerText.text('sections');
    this.$triggerIcon.removeClass(CLASSES.ARROW_UP);

    this.isNavOpen = false;
  }

  /**
   * Calculate top position based on hero size
   *
   * @method calculateTop
   * @public
   */
  calculateTop() {
    const heroHeight = this.$hero.outerHeight();
    const contentTop = heroHeight + 56;

    this.$content.css('top', contentTop);
    this.showLeftNav();
  }

  /**
   * Shows the left nav
   */
  showLeftNav() {
    if (!this.shown) {
      this.$content.addClass(CLASSES.ACTIVE);
      this.shown = true;
    }
  }

  /**
   * Reset top position on resize
   *
   * @method resetTop
   * @public
   */
  resetTop() {
    this.$content.css('top', '');
  }
}
