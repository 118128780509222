const GoogleTranslatePosition = () => {
  let state = {
    isMobile: false,
    TranslateElement: document.getElementById("google_translate_container"),
  }

  function setIsMobile() {
    let BrowserWidth = document.documentElement.clientWidth < 992;

    if (state.isMobile !== BrowserWidth) {
      state.isMobile = BrowserWidth;

      MoveElements();
    }
  }

  function MoveElements() {
    state.TranslateElement.parentNode.removeChild(state.TranslateElement);

    if (state.isMobile) {
      document.getElementsByClassName(`utilityLinks`)[0].appendChild(state.TranslateElement);
    } else {
      document.getElementById(`utilityNav-googleTranslate`).appendChild(state.TranslateElement);
    }
  }

  if (state.TranslateElement) {
    setIsMobile();
    window.onresize = setIsMobile;
  }
};

export default GoogleTranslatePosition;
