/*
 * @fileOverview BookingForm module definition
 * This File differs from the other modules in that we need a central place to
 * handle booking logic. We aren't interested in the visual display or
 * functionality of the form here. We are simply interested in the
 * cross-cutting concern of processing data and submitting it appropriately.
 */

import View from 'views/View';
import Utils from 'utils/Utils';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'SUBMIT_BUTTON': '.js-submitBooking',
  'FORM': '.js-bookingProvider-form',
  'FIELD_CONTAINERS': '.js-field-container'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'SUBMIT': 'submit',
  'FORCE_SUBMIT': 'force:submit'
};

/**
 * A reference to the data attributes used in this view
 *
 * @property DATA
 * @type {Object}
 * @private
 */
const DATA = {
  'PROVIDER': 'booking-provider'
};


/**
 * Submits data to different providers
 *
 * @class BookingFormView
 */
export default class BookingFormView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {BookingFormView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$submit = this.$element.find(SELECTORS.SUBMIT_BUTTON);
    this.$form = this.$element.find(SELECTORS.FORM).addBack(SELECTORS.FORM);
    this.$containers = this.$element.find(SELECTORS.FIELD_CONTAINERS);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ExampleView}
   * @chainable
   * @public
   */
  enable() {
    this.$submit.on(EVENTS.CLICK, e => this.handleFormSubmit(e));
    this.$element.on(EVENTS.FORCE_SUBMIT, e => this.handleFormSubmit(e));

    return this;
  }

  /**
   * Handles click event of the submit button
   * There isn't much to do here, but there are alot more booking providers,
   * One issue we've had is that the mobile design forced the submit button
   * outside of the form, so we need a module to bring the form and button
   * together
   *
   * @method handleMenuButtonClick
   * @public
   */
  handleFormSubmit(event) {
    event.preventDefault();

    this.disableDuplicateInputs(event.exceptedDuplicates);
    this.appendMarriottInputs();
    this.$form.submit();
    this.enableDuplicateInputs();
  }

  appendMarriottInputs() {
    let mid = this.$element.attr('data-mid').replace(/\/$/, "");

    if (mid) {
      this.$containers.find('input:enabled').each((idx, item) => {
        const $item = $(item);
        let appendage = "?";

        if (mid.includes('?') || mid.includes(encodeURIComponent('?'))) {
          appendage = "&";
        }

        mid += `${appendage}${$item.attr('name')}=${$item.val()}`;
        $item.prop('disabled', true);
      });

      this.$element.append(`<input type="hidden" name="mid" value="${mid}" />`);
    }
  }

  /**
  * [disableDuplicateInputs disables hidden inputs that are not on a visible modules.
  * Used in particular by the mobile and desktop versions of mobile booking widgets where There
  * is duplicated markup to achieve designs.]
   * @param  {[type]} parentOfExcepted [class selector of items that are ok to be hidden]
   * @return {[type]}                  [void]
   */
  disableDuplicateInputs(parentOfExcepted) {
    if (parentOfExcepted) {
      this.$containers.filter(':hidden').find('input[type="hidden"]').not(`${parentOfExcepted} input[type="hidden"]`).prop('disabled', true);
    }
    else {
      this.$containers.filter(':hidden').find('input[type="hidden"]').prop('disabled', true);
    }
  }

  /**
   * [enableDuplicateInputs enables duplicated hidden fields, in particular after form submission]
   * @return {[type]} [void]
   */
  enableDuplicateInputs() {
    this.$containers.filter(':hidden').find('input[type="hidden"]').prop('disabled', false);
  }
}
