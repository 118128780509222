/*
 * @fileOverview FeaturedDropdownView module definition
 */

import DropdownView from 'views/dropdowns/DropdownView';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'FEATURE_GROUP': '.featureGroup'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'FEATURE_GROUP_ACTIVE': 'featureGroup_active'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

const DATA = {
  'FEATURE_GROUP': 'featuregroup'
};

/**
 * Control for changing featured content between the Feature Groups (cards)
 *
 * @class FeaturedDropdownView
 */
export default class FeaturedDropdownView extends DropdownView {
  /**
   * Sets properties and kicks off component
   *
   * @param {jQuery} $element The base parent element of the view
   * @constructor
   */
  constructor($element) {
    super($element);
    this.$contentSwap.first().toggleClass(CLASSES.FEATURE_GROUP_ACTIVE);
  }

  /**
   * Swaps out featured cards based on selection
   *
   * @param {Event} e [The event from making a selection in the dropdown]
   * @method updateAction
   */
  updateAction(e) {
    const featureGroup = $(e.currentTarget).data(DATA.FEATURE_GROUP);
    const activeFeatureGroupName = e.currentTarget.innerText;
    const $activeFeatureGroup = $(SELECTORS.FEATURE_GROUP).filter(`[data-${DATA.FEATURE_GROUP}="${featureGroup}"]`);

    this.toggleDropdown();

    if (!$activeFeatureGroup.hasClass(CLASSES.FEATURE_GROUP_ACTIVE)) {
      this.updateTriggerText(activeFeatureGroupName);
      this.$contentSwap.removeClass(CLASSES.FEATURE_GROUP_ACTIVE);
      $activeFeatureGroup.addClass(CLASSES.FEATURE_GROUP_ACTIVE);
    }
  }
}
