
/*
 * @fileOverview BookingWidgetView module definition
 */

import View from 'views/View';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'BUTTON': '.js-booking-button',
  'FORM': '.js-booking-form'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'HIDDEN': 'displayNone',
  'BUTTON_ACTIVE': 'btn_textBookingActive'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

/**
 * Toggles subNav height and aria roles in an BookingWidgetView view component
 *
 * @class BookingWidgetView
 */
export default class BookingWidgetView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {BookingWidgetView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$buttons = this.$element.find(SELECTORS.BUTTON);
    this.formGroups = this.buildFormGroups(this.$buttons);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {BookingWidgetView}
   * @chainable
   * @public
   */
  enable() {
    this.$buttons.on(EVENTS.CLICK, e => this.handleButtonClick(e));

    return this;
  }

  /**
   * Creates an object with the forms grouped
   * by their booking-type
   *
   * @method buildFormGroups
   * @returns {BookingWidgetView}
   * @public
   */
  buildFormGroups($buttons) {
    const formGroups = {};

    $buttons.toArray().forEach(button => {
      const type = $(button).data('bookingType');

      formGroups[type] = this.$element.find(`${SELECTORS.FORM}[data-booking-type="${type}"]`);
    });

    return formGroups;
  }

  /**
   * Handles click event on buttons
   *
   * @method handleButtonClick
   * @returns {BookingWidgetView}
   * @public
   */
  handleButtonClick(e) {
    e.preventDefault();

    this.removeActiveClasses();
    this.showActiveForm($(e.currentTarget));
    this.scrollTo();
  }

  /**
   * Scrolls to current active tab
   *
   * @method scrollTo
   * @returns {BookingWidgetView}
   * @public
   */
  scrollTo() {
    $('html, body').animate({
        scrollTop: $('.' + CLASSES.BUTTON_ACTIVE).offset().top-100,
      }, 500, 'swing'
    )
  }

  /**
   * Adds css classes to form & button to
   * show them to the user
   *
   * @method showActiveForm
   * @returns {BookingWidgetView}
   * @public
   */
  showActiveForm($button) {
    const bookingType = $button.data('bookingType');
    const bookingForm = this.formGroups[bookingType];

    $button.addClass(CLASSES.BUTTON_ACTIVE);
    bookingForm.removeClass(CLASSES.HIDDEN);
  }

  /**
   * Removes css classes from form & button to
   * hide them to the user
   *
   * @method removeActiveClasses
   * @returns {BookingWidgetView}
   * @public
   */
  removeActiveClasses() {
    this.$buttons.removeClass(CLASSES.BUTTON_ACTIVE);
    Object.values(this.formGroups).forEach(el => $(el).addClass(CLASSES.HIDDEN));
  }
}
