
/*
 * @fileOverview DatepickerView module definition
 */

import View from 'views/View';
import Utils from 'utils/Utils';
import MobileFlyoutView from 'views/MobileFlyoutView';
import moment from 'moment';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'DATEPICKER_CONTAINER': '.js-datepickerContainer',
  'DATEPICKER_WIDGET': '.js-datepicker',
  'DISPLAY_INPUT': '.js-displayInput',
  'START_INPUT': '.js-startInput',
  'END_INPUT': '.js-endInput',
  'CLOSE_BUTTON': '.js-closeButton',
  'CLOSE_ICON': '.js-closeIcon',
  'NEXT_FIELD': '.js-datepicker-nextField',
  'DATE_ACTIVE': '.ui-state-active'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'HIDDEN': 'isVisuallyHidden',
  'ICON_UP': 'mix-icon_rotate270',
  'ICON_DOWN': 'mix-icon_rotate90',
  'BOLD': 'mix-btn_bold'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click',
  'FOCUS': 'focus',
  'KEYDOWN': 'keydown'
};

/**
 * A reference to the data attributes used in this view
 *
 * @property DATA
 * @type {Object}
 * @private
 */
const DATA = {
  'MINDATE': 'min-date-offset',
  'MINDATE_STRING': 'min-date'
};


/**
 * Toggles subNav height and aria roles in an DatepickerView view component
 *
 * @class DatepickerView
 */
export default class DatepickerView extends View {
  /*
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @property afterInit
   * @returns {View}
   * @chainable
   * @public
   */
  afterInit() {
    this.startDate = undefined;
    this.endDate = undefined;
    this.dateFormat = this.$element.data('dateFormat');

    this.updateInitialDates();

    return this;
  }
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {DatepickerView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$displayInput = this.$element.find(SELECTORS.DISPLAY_INPUT);
    this.$startInput = this.$element.find(SELECTORS.START_INPUT);
    this.$endInput = this.$element.find(SELECTORS.END_INPUT);
    this.$closeButton = this.$element.find(SELECTORS.CLOSE_BUTTON);
    this.$closeIcon = this.$closeButton.find(SELECTORS.CLOSE_ICON);
    this.$nextField = this.$element.find(SELECTORS.NEXT_FIELD);

    this.$datepickerFlyout = new MobileFlyoutView(this.$element.find(SELECTORS.DATEPICKER_CONTAINER), {
      'afterShow': this.afterShow.bind(this),
      'afterHide': this.afterHide.bind(this)
    });

    this.$datepicker = $(this.$element[0].querySelector(SELECTORS.DATEPICKER_WIDGET)).datepicker({
      'numberOfMonths': Utils.isLargeDesktopViewport() ? this.minimumMonthsShown : 12,
      'onSelect': (d, $d) => this.onDateSelect(d, $d),
      'beforeShowDay': (date) => this.highlightCells(date),
      'minDate': this.$element.data(DATA.MINDATE)
    });

    datePickHandler(this.$datepicker[0], this.$closeButton);

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {DatepickerView}
   * @chainable
   * @public
   */
  enable() {
    $(document).on(EVENTS.CLICK, e => this.handleBodyClick(e));
    this.$displayInput.on(`${EVENTS.CLICK} ${EVENTS.KEYDOWN}`, e => this.handleShowDatepicker(e));
    this.$closeButton.on(EVENTS.CLICK, e => this.handleShowDatepicker(e));

    return this;
  }

  handleBodyClick(e) {
    const parent = $(e.target).closest(this.closestParent)[0];
    const validChild = $(e.target).hasClass('ui-corner-all') || $(e.target).hasClass('ui-icon');

    if (!parent && !validChild) this.handleHideDatepicker();
  }

   handleShowDatepicker(e) {
     this.$datepickerFlyout.handleToggle(e);
   }

   handleHideDatepicker() {
     this.$datepickerFlyout.hide();
   }

  /**
   * Handles datepicker select event
   *
   * @method onDateSelect
   * @public
   */
  onDateSelect(selectedDate, $datepicker) {
    this.setInternalDates(selectedDate);

    this.$displayInput.addClass(CLASSES.BOLD);

    this.updateHiddenInputs();
    this.updateDisplay();

    setTimeout(() => {
      $datepicker.dpDiv.find(SELECTORS.DATE_ACTIVE).focus();
    }, 200);
  }

  /**
   * Updates initial date if present
   *
   * @method updateInitialDates
   * @public
   */
  updateInitialDates() {
    this.$startInput.val(moment(this.$element.data(DATA.MINDATE_STRING), "M/D/YYYY").format(this.dateFormat));
  }

  /**
   * Updates hidden inputs
   *
   * @method updateHiddenInputs
   * @public
   */
  updateHiddenInputs() {
    this.$startInput.val(moment(this.startDate).format(this.dateFormat));

    if (this.$endInput.length === 1) {
      this.$endInput.val(moment(this.endDate).format(this.dateFormat));
    }
  }

  /**
   * Called after flyout is shown
   *
   * @method afterShow
   * @public
   */
  afterShow() {
    this.$closeIcon.removeClass(CLASSES.ICON_DOWN).addClass(CLASSES.ICON_UP);
    this.$closeButton.focus();

    datePickHandler(this.$datepicker[0], this.$closeButton);
  }

  /**
   * Called after flyout is closed
   *
   * @method afterHide
   * @public
   */
  afterHide() {
    this.$closeIcon.removeClass(CLASSES.ICON_UP).addClass(CLASSES.ICON_DOWN);
    this.$displayInput.focus();
  }
}
