/*
 * @fileOverview YouTubeView module definition
 */

import View from './View';
import { TweenMax, Expo } from 'views/GsapLoader';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'PLAYER': '.js-youtube'
};

/**
 * A reference to the animation settings used in this view
 *
 * @property ANIMATION
 * @type {Object}
 * @private
 */
 const ANIMATION = {
   'TIME_OUT': 1,
   'EASE_OUT': Expo.easeInOut
 };


/**
 * Handles YouTube specific interactions
 *
 * @class YouTubeView
 */
export default class YouTubeView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {YouTubeView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$player = this.$element.find(SELECTORS.PLAYER);

    return this;
  }

  /**
   * Provides a post initialization hook for additional setup
   * outside of event and child setup
   *
   * @method afterInit
   * @returns {YouTubeView}
   * @public
   */
  afterInit() {
    if (this.$player.length > 0) {
      this.loadApiScript();
    }

    return this;
  }

  /**
   * Loads YouTube API Script
   *
   * @method loadApiScript
   * @public
   */
  loadApiScript() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      this.initPlayer();
    }
  }

  /**
   * Initialize any YouTube player on page
   *
   * @method initPlayer
   * @public
   */
  initPlayer() {
    this.$player.each((index, element) => {
      const player = new YT.Player(element.id, {
        'events': {
          'onStateChange': e => this.onPlayerStateChange(e)
        }
      });
    });
  }

  /**
   * Show/Hide frame content based on player status
   *
   * @method toggleContent
   * @param {Int} playerStatus [YouTube Player Status]
   * @param {jQuery} $target
   * @public
   */
  toggleContent(playerStatus, $target) {
    if (playerStatus === 1) { // playing
      TweenMax.to($target.next(), ANIMATION.TIME_OUT, {
      'opacity': 0,
      'ease': ANIMATION.EASE_OUT,
      'onComplete': () => {
        $target.next().hide();
      }
    });
    }
  }

  /**
   * Calls methods on YouTube state change
   *
   * @method onPlayerStateChange
   * @public
   */
  onPlayerStateChange(e) {
    this.toggleContent(e.data, $(e.target.a));
  }
}
