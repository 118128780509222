import Swiper from '../vendor/TimeCircles.js';

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName('time-circles').length > 0) {
    var color = "#FFFFFF";
    var bgColor;
    (function () {
      var $p = $("<div class='band_colorPrimary'></div>").hide().appendTo("body");
      bgColor = $p.css("background-color");
      $p.remove();
    })();
    import('../vendor/TimeCircles.js');
      $(".time-circles").TimeCircles({
        "animation": "smooth",
        "bg_width": 0.5,
        "fg_width": 0.05,
        "circle_bg_color": bgColor,
        "count_past_zero": false,
        "time": {
          "Days": {
            "text": "Days",
            "color": color,
            "show": true
          },
          "Hours": {
            "text": "Hours",
            "color": color,
            "show": true
          },
          "Minutes": {
            "text": "Minutes",
            "color": color,
            "show": true
          },
          "Seconds": {
            "text": "Seconds",
            "color": color,
            "show": true
          }
        }
      });
      window.addEventListener("resize", function () {
        $(".time-circles").TimeCircles().rebuild();
      }, false);
      window.addEventListener("orientationchange", function () {
          $(".time-circles").TimeCircles().rebuild();
      }, false);
    }
})
