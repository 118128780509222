/*
 * @fileOverview Application Controller definition
 */

import AccordionView from 'views/AccordionView';
import AmadeusFormView from 'views/AmadeusFormView';
import AmbientVideoView from 'views/AmbientVideoView';
import ActivityCarouselView from 'views/ActivityCarouselView';
import BookingFormView from 'views/BookingFormView';
import BookingNavView from 'views/BookingNavView';
import BookingWidgetView from 'views/BookingWidgetView';
import CarouselView from 'views/CarouselView';
import ConditionsDrawerView from 'views/ConditionsDrawerView';
import DarkSkyIconView from 'views/DarkSkyIconView';
import DropdownView from 'views/dropdowns/DropdownView';
import DualDatepickerView from 'views/DualDatepickerView';
import FeaturedDropdownView from 'views/dropdowns/FeaturedDropdownView';
import FormDropdownView from 'views/dropdowns/FormDropdownView';
import FilterView from 'views/FilterView';
import FormView from 'views/FormView';
import GalleryView from 'views/GalleryView';
import GoogleTranslateView from 'views/dropdowns/GoogleTranslateView';
import GsapLoader from 'views/GsapLoader';
import GuestNumberView from 'views/GuestNumberView';
//import HeroView from 'views/HeroView';
import HiddenButFocusableView from 'views/HiddenButFocusableView';
import LeftHandNavView from 'views/LeftHandNavView';
import MarriottTracking from 'services/MarriottTracking';
import ModalView from 'views/ModalView';
import NavisFormView from 'views/NavisFormView';
import NavView from 'views/NavView';
import NewsletterFormView from 'views/NewsletterFormView';
import PageContentNavView from 'views/PageContentNavView';
import PopoutView from 'views/PopoutView';
import ProductComparisonView from 'views/ProductComparisonView';
import ProductDetailsView from 'views/ProductDetailsView';
import QuarantineView from 'views/QuarantineView';
import ScrollableHList from 'views/ScrollableHList';
import SearchableResults from 'views/SearchableResults';
import SearchView from 'views/SearchView';
import SingleDatepickerView from 'views/SingleDatepickerView';
import SingleLocationMapView from 'views/SingleLocationMapView';
import UnitsToggleView from 'views/UnitsToggleView';
import ToasterView from 'views/ToasterView';
import Utils from 'utils/Utils';
import YouTubeView from 'views/YouTubeView';

//new components
import './components/HeroCrossfade';
import GoogleTranslatePosition from './Services/GoogleTranslate';
import './components/Navis';
import './components/TimeCircles';


/**
 * Initial application setup. Runs once on every page load.
 *
 * @class App
 * @constructor
 */
export default class App {
  // Creates modules base on the data-module attribute
  constructor () {
    this.dataModules = {
      'accordion': AccordionView,
      'activity-carousel': ActivityCarouselView,
      'amadeus-form': AmadeusFormView,
      'ambient-video': AmbientVideoView,
      'booking': BookingWidgetView,
      'booking-form': BookingFormView,
      'booking-nav': BookingNavView,
      'carousel': CarouselView,
      'conditionsDrawer': ConditionsDrawerView,
      'darkskyicon': DarkSkyIconView,
      'dropdown': DropdownView,
      'dual-datepicker': DualDatepickerView,
      'featuredDropdown': FeaturedDropdownView,
      'formDropdown': FormDropdownView,
      'filter': FilterView,
      'form': FormView,
      'gallery': GalleryView,
      'google-translate': GoogleTranslateView,
      'guest-number-picker': GuestNumberView,
      'hiddenButFocusable': HiddenButFocusableView,
      //'hero': HeroView,
      'left-hand-nav': LeftHandNavView,
      'map-single-location': SingleLocationMapView,
      'modal': ModalView,
      'nav': NavView,
      'navis-form': NavisFormView,
      'newsletter-form': NewsletterFormView,
      'page-content-nav': PageContentNavView,
      'popout': PopoutView,
      'product-comparison': ProductComparisonView,
      'product-details': ProductDetailsView,
      'quarantine': QuarantineView,
      'scrollable-hList': ScrollableHList,
      'searchable-results': SearchableResults,
      'search': SearchView,
      'single-datepicker': SingleDatepickerView,
      'units-toggle': UnitsToggleView,
      'toaster': ToasterView,
      'youtube': YouTubeView
   };

    this.controllerClasses = {};

    this.controllers = [];

    this.init();
  }

  /**
   * Initializes the application and kicks off loading of prerequisites.
   *
   * @method init
   * @public
   */
  init () {
    this.initViewComponents();
    this.onApplicationStart();
    // this.initControllers();
  }

  /**
   * Exposes a kick-off method for jQuery components
   * Creates moduleNameViews Array if not already created
   * Creates instance of view per module
   *
   * @method initViewComponents
   * @public
   */
  initViewComponents() {
    Object.keys(this.dataModules).forEach(name => {
      const $modules = $(`[data-module="${name}"]`);

      $modules.each((index, el) => {
        const viewName = `${name}Views`;

        if (!this[viewName]) {
          this[viewName] = [];
        }

        this[viewName][index] = new this.dataModules[name](el);
      });
    });
  }

  /**
   * Exposes a kick-off method for Controllers
   * An element assigned to a Controller must have a data-controller="" attr
   * It must also have a data-child="" attr to let the controller know its view
   *
   * @method initControllers
   * @public
   */
  initControllers() {
    Object.keys(this.controllerClasses).forEach(name => {
      const children = [];

      $(`[data-controller="${name}"]`).each((index, el) => {
        children.push($(el));
      });

      this.controllers[name] = new this.controllerClasses[name](children);
    });
  }

  onApplicationStart() {
    if (Utils.getParameterByName('resetLocalStorage') === 'true') {
      localStorage.clear()
    }

    MarriottTracking.rewriteTrackingUrls();
    GoogleTranslatePosition();
  }
}
