import Swiper from '../vendor/swiper.min.js';

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementsByClassName('swiper-container').length > 0) {
    import(/*webpackChunkName:"swiper"*/'../vendor/swiper.min.js')
      .then(({ default: Swiper }) => {
        let mySwiper = new Swiper('.swiper-container', {
          loop: true,
		  grabCursor: true,
		  threshold: 20,
		  preventClicks: true,
          speed: 500,
		  watchOverflow: true,
          autoplay: {
            delay: 7000,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        });
      });
  }
})
