/*
 * @fileOverview ToasterView module definition
 */

import View from 'views/View';
import ModalView from 'views/ModalView';
import LocalStorageService from 'services/LocalStorageService';
import { TweenMax, Power1 } from 'views/GsapLoader';

/**
 * A reference to the selectors used in this view
 *
 * @property SELECTORS
 * @type {Object}
 * @private
 */
const SELECTORS = {
  'CLOSE': '.js-toaster-close',
  'MSG': '.js-toast-msg',
  'MODAL': '.js-toast-modal',
  'MSG_BUTTON': '.js-toast-message'
};

/**
 * A reference to the classes used in this view
 *
 * @property CLASSES
 * @type {Object}
 * @private
 */
const CLASSES = {
  'HIDE_TOASTER': 'mix-band_hidden'
};

/**
 * A reference to the events used in this view
 *
 * @property EVENTS
 * @type {Object}
 * @private
 */
const EVENTS = {
  'CLICK': 'click'
};

/**
 * A reference to the animation settings used in this view
 *
 * @property ANIMATION
 * @type {Object}
 * @private
 */
const ANIMATION = {
  'TIME': 0.5,
  'EASE': Power1.easeOut
};

/**
 * Open and close toaster
 *
 * @class ToasterView
 */
export default class ToasterView extends View {
  /**
   * Create any child objects or references to DOM elements.
   *
   * @method createChildren
   * @returns {ToasterView}
   * @public
   * @chainable
   */
  createChildren() {
    this.$closeBtn = this.$element.find(SELECTORS.CLOSE);
    this.$modal = this.$element.find(SELECTORS.MODAL);
    this.$modalView = new ModalView(this.$modal, {
      'afterHide': this.afterModalHide.bind(this)
    });
    this.$msgButton = this.$element.find(SELECTORS.MSG_BUTTON);
    this.msg = this.$element.find(SELECTORS.MSG).text().trim();

    return this;
  }

  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {ToasterView}
   * @chainable
   * @public
   */
  enable() {
    this.$closeBtn.on(EVENTS.CLICK, e => this.closeToaster(e));
    this.$msgButton.on(EVENTS.CLICK, e => this.handleMessageClick(e));
    this.showToaster();

    return this;
  }

  /**
   * Handles click event of user clicking message bar
   *
   * @method handleMessageClick
   * @public
   */
  handleMessageClick() {
    const hasMoreInfo = this.$element.data('moreInfo') === 'True';

    if (!hasMoreInfo) {
      this.closeToaster();
      return;
    }

    this.$modalView.show();
  }

  afterModalHide() {
    this.closeToaster();
  }

  /**
   * Show toaster element if cookie not set
   *
   * @method showToaster
   * @returns {ToasterView}
   * @public
   */
  showToaster() {
    if (LocalStorageService.itemIsStored(this.msg)) {
      return;
    }

    this.$element.removeClass(CLASSES.HIDE_TOASTER);
  }

  /**
   * Close toaster element
   *
   * @method closeToaster
   * @returns {ToasterView}
   * @public
   */
  closeToaster() {
    TweenMax.to(this.$element, ANIMATION.TIME, {
      'height': 0,
      'ease': ANIMATION.EASE,
      'onComplete': () => this.$element.addClass(CLASSES.HIDE_TOASTER)
    });

    LocalStorageService.setItem30Mins(this.msg);
  }
}
