import Utils from 'utils/Utils';

/**
 * Helpful methods for tracking item dismissals
 *
 * @name LocalStorageService
 * @type {Object}
 */
const LocalStorageService = {
  /**
   * Returns an Object from localStorage with info relating to a item.
   *
   * @method getItem
   * @param {String} str [The item's string value]
   * @return {Object} Info related to the item
   */
  getItem(str) {
    const hash = Utils.hashCode(str);

    return JSON.parse(localStorage.getItem(hash));
  },

  /**
   * Stores information related to a item in localStorage.
   * Sets an expiration date of 1 week.
   *
   * @method setItem
   * @param {String} str [The item's string value]
   */
  setItem(str, expiry) {
    const hash = Utils.hashCode(str);
    let today = new Date();
    let expiration = expiry || new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);

    localStorage.setItem(hash, JSON.stringify({
      'value': hash,
      expiration
    }));
  },

  /**
 * Stores information related to a item in localStorage.
 * Sets an expiration date of 30 minutes.
 *
 * @method setItem30Mins
 * @param {String} str [The item's string value]
 */
  setItem30Mins(str, expiry) {
    const hash = Utils.hashCode(str);
    let today = new Date();
    let expiration = expiry || new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes()+30);

    localStorage.setItem(hash, JSON.stringify({
      'value': hash,
      expiration
    }));
  },

  /**
   * Deletes an item from localStorage
   *
   * @method removeItem
   * @param {String} str [The item's string value]
   */
  removeItem(str) {
    const hash = Utils.hashCode(str);

    localStorage.removeItem(hash);
  },

  /**
   * Returns whether or not a item is stored in localStorage
   *
   * @method itemIsStored
   * @param {String} str [The item's string value]
   * @return {Bool}
   */
  itemIsStored(str) {
    const item = this.getItem(str);

    if (!item) {
      return false;
    } else if (this.itemIsExpired(item)) {
      this.removeItem(str);

      return false;
    }

    return true;
  },

  /**
   * Returns whether or not a item storage item is expired
   *
   * @method itemIsStored
   * @param {Object} item [The item's storage value]
   * @return {Bool}
   */
  itemIsExpired(item) {
    const exp = new Date(item.expiration).getTime();
    let now = new Date().getTime();

    return exp < now;
  }
};

export default LocalStorageService;
