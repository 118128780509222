/*
 * @fileOverview AmbientVideoView module definition
 */

import View from 'views/View';
import Blazy from 'blazy';

/**
 * Loads video lazily in an AmbientVideoView view component
 *
 * @class AmbientVideoView
 */
export default class AmbientVideoView extends View {
  /**
   * Enable event handlers.
   * Exits early if component already enabled.
   *
   * @method enable
   * @returns {AmbientVideoView}
   * @chainable
   * @public
   */
  enable() {
    this.bLazy = new Blazy({
      container: '.js-ambient-video',
      success: (ele) => {
        setTimeout(function () {
          ele.classList.add('loadComplete');
        }, 1000);
      }
    });

    return this;
  }
}
